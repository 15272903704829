'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { DialogBase } from '../../../DialogBase/DialogBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { TwoFaSectionTitle } from '../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../shared/TwoFaSectionDescription';
import { TwoFaSectionButtonsWrapper } from '../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../ButtonBase/ButtonBase';
import { signOutForTwoFaDeletion } from '../../../../../auth';
import { useState } from 'react';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { css } from '@bts-web/utils-style-engine';

const TriggerDeleteTwoFaModal: ModalComponent = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const { t } = useClientTranslation();

  const onLogoutChangeInfo = async () => {
    setLoading(true);

    const resp = await signOutForTwoFaDeletion();

    // TODO TEMPORARY CAST
    if ((resp as any).errors) {
      processErrors((resp as any).errors);

      return;
    }

    setLoading(false);

    return;
  };

  return (
    <DialogBase
      id="trigger-delete-2fa-modal"
      open
      onOpenChange={onClose}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_disable_logout_title' as 'done')}
      animate={'top'}
    >
      <TwoFaLayoutWrapper>
        <TwoFaSectionTitle
          title={`${t('2fa_disable_are_you_sure' as 'done')}`}
        />
        <TwoFaSectionDescription
          textContent={`${t('2fa_disable_description_body' as 'done')}`}
        />
      </TwoFaLayoutWrapper>
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={loading}
          onClick={onLogoutChangeInfo}
          visual="accent"
          size="large"
          fullWidth
          disabled={loading}
        >
          {t('2fa_disable_accept_button' as 'done')}
        </ButtonBase>
        <ButtonBase
          disabled={loading}
          isLoading={loading}
          visual={'ghost'}
          onClick={onClose}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_welcome_cta_skip')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </DialogBase>
  );
};

export { TriggerDeleteTwoFaModal };
