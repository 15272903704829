'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { DialogBase } from '../../../DialogBase/DialogBase';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { useCallback, useState } from 'react';
import { onCancelDeleteTwoFa } from '../../../../../auth';
import { useDeleteTwoFactorAuthState } from './utils/useDeleteTwoFactorAuthState';
import { DeleteTwoFaModalStep } from './utils/types';
import { DeleteTwoFaInitialScreen } from './subPages/DeleteTwoFaInitialScreen/DeleteTwoFaInitialScreen';
import { ProgressIndicatorWithSteps } from '../../../ProgressIndicatorWithSteps/ProgressIndicatorWithSteps';
import { DeleteTwoFaSelectDeleteVerificationMethod } from './subPages/DeleteTwoFaSelectDeleteVerificationMethod/DeleteTwoFaSelectDeleteVerificationMethod';
import { DeleteTwoFaMethodAppCode } from './subPages/DeleteTwoFaMethodAppCode/DeleteTwoFaMethodAppCode';
import { DeleteTwoFaConfirmationScreen } from './subPages/DeleteTwoFaConfirmationScreen/DeleteTwoFaConfirmationScreen';
import { DeleteTwoFaMethodRecoveryCode } from './subPages/DeleteTwoFaMethodRecoveryCode/DeleteTwoFaMethodRecoveryCode';

const DeleteTwoFaModal: ModalComponent = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { currentPage, onContinue, currentStepNumber, setPage } =
    useDeleteTwoFactorAuthState();

  const { t } = useClientTranslation();

  const onDismiss = async () => {
    setLoading(true);

    await onCancelDeleteTwoFa();

    onClose();

    setLoading(false);
  };

  const isCloseDisabledInTheCorner =
    currentPage === DeleteTwoFaModalStep.INITIAL;

  const backToPreviousStep = useCallback(() => {
    switch (currentPage) {
      case DeleteTwoFaModalStep.METHOD_RECOVERY:
        return () => setPage(DeleteTwoFaModalStep.METHOD_SELECTION);

      case DeleteTwoFaModalStep.METHOD_AUTHENTICATOR:
        return () => setPage(DeleteTwoFaModalStep.METHOD_SELECTION);

      default:
        return undefined;
    }
  }, [currentPage, setPage]);

  return (
    <DialogBase
      id="delete-2fa-modal"
      open
      onOpenChange={onDismiss}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_disable_logout_title' as 'done')}
      animate={'top'}
      closeIcon={isCloseDisabledInTheCorner ? null : undefined}
      backAction={backToPreviousStep()}
      belowHeaderElement={
        <ProgressIndicatorWithSteps
          currentStep={currentStepNumber}
          totalSteps={4}
        />
      }
    >
      <TwoFaLayoutWrapper>
        {currentPage === DeleteTwoFaModalStep.INITIAL && (
          <DeleteTwoFaInitialScreen
            onContinue={onContinue}
            onDismiss={onDismiss}
            dismissLoading={loading}
          />
        )}
        {currentPage === DeleteTwoFaModalStep.METHOD_SELECTION && (
          <DeleteTwoFaSelectDeleteVerificationMethod
            dismissLoading={loading}
            onSelectAuthenticator={() =>
              setPage(DeleteTwoFaModalStep.METHOD_AUTHENTICATOR)
            }
            onSelectRecovery={() =>
              setPage(DeleteTwoFaModalStep.METHOD_RECOVERY)
            }
          />
        )}
        {currentPage === DeleteTwoFaModalStep.METHOD_AUTHENTICATOR && (
          <DeleteTwoFaMethodAppCode
            onContinue={onContinue}
            dismissLoading={loading}
          />
        )}
        {currentPage === DeleteTwoFaModalStep.METHOD_RECOVERY && (
          <DeleteTwoFaMethodRecoveryCode
            onContinue={onContinue}
            dismissLoading={loading}
          />
        )}
        {currentPage === DeleteTwoFaModalStep.CONFIRMATION && (
          <DeleteTwoFaConfirmationScreen onClose={onClose} />
        )}
      </TwoFaLayoutWrapper>
    </DialogBase>
  );
};

export { DeleteTwoFaModal };
