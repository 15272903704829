import { AccentTradeControllerProps } from '../types';
import { getTradeTranslations } from '../../utils/getTradeTranslations';
import { redirect } from 'next/navigation';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import {
  NavigationBackButton,
  NavigationButton,
} from '../../../navigation/index';
import { NavigationBar } from '../../../common';

const AcceptTradeController = async ({
  offerData,
  variant,
  children,
  translationUtil,
  currency,
}: AccentTradeControllerProps) => {
  const translations = getTradeTranslations(translationUtil);

  if (!offerData) {
    console.warn('%cNo trade offer data found', 'color:red');

    if (!isDevelopmentEnv()) {
      redirect('/error/generic');
    }

    return null;
  }

  const sectionTitle = variant === 'buy' ? translations.buy : translations.sell;

  return (
    <>
      <NavigationBar
        title={sectionTitle}
        NavigationLeftSlot={NavigationBackButton && <NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton icon="dismiss" to="/assets/portfolio" />
        }
      />
      {children &&
        children({
          initialOfferData: offerData,
          translations,
          currency,
        })}
    </>
  );
};

export { AcceptTradeController };
