'use client';

import { useComposableModal } from '@bts-web/utils-context';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { SetupTwoFaModal } from './twoFaModals/SetupTwoFaModal/SetupTwoFaModal';
import { UserAccessModal } from './UserAccessModal/UserAccessModal';
import { TriggerDeleteTwoFaModal } from './twoFaModals/TriggerDeleteTwoFaModal/TriggerDeleteTwoFaModal';
import { DeleteTwoFaModal } from './twoFaModals/DeleteTwoFaModal/DeleteTwoFaModal';
import { TriggerSetupTwoFaModal } from './twoFaModals/TriggerSetupTwoFaModal/TriggerSetupTwoFaModal';

const ComposableModalPlayGround: FC = () => {
  const { showModal } = useComposableModal();

  return (
    <div
      className={css({
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        gap: '10px',
      })}
    >
      <ButtonBase onClick={() => showModal(TriggerSetupTwoFaModal)}>
        Setup trigger logout Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(DeleteTwoFaModal)}>
        Delete Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(TriggerDeleteTwoFaModal)}>
        Trigger Delete Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(SetupTwoFaModal)}>
        Setup Two FA Modal
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: 'infoApprovalPending',
          })
        }
      >
        User access : Info Approval Pending
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: 'readOnly',
          })
        }
      >
        User access : read only
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: 'warningTrade',
          })
        }
      >
        User access : warning trade
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: 'warningTrade',
          })
        }
      >
        User access : warning transfer
      </ButtonBase>
    </div>
  );
};

export { ComposableModalPlayGround };
