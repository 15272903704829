import { useState, useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { TradeOfferOperation, AmountFor } from '@bts-web/data-layer/server';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { getConvertedSummary } from '../../utils/getConvertedSummary';
import {
  getInputValueFromLocalStorage,
  setInputValueToLocalStorage,
} from '../../utils/localStorageInputValueUtils';
import { applyAppropriatenessCheck } from '../../utils/applyAppropriatenessCheck';
import { TradeFormProps } from '../types';
import { initiateTrade } from '../../../../common/gqlActions/initiateTrade.action';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import {
  getAmountInputPlaceHolder,
  valueFromAssetToFiat,
} from '../../../../common';
import { useTradeInput } from './useTradeInput';

export interface IUseTradeForm {
  assetDetails: TradeFormProps['assetDetails'];
  currentTradeDetails: TradeFormProps['currentTradeDetails'];
  staticValues: TradeFormProps['staticValues'];
}

export const useTradeForm = ({
  assetDetails,
  currentTradeDetails,
  staticValues,
}: IUseTradeForm) => {
  const router = useRouter();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [loading, setLoading] = useState(false);

  const [initialInputValue] = useState(
    getInputValueFromLocalStorage({
      assetId: assetDetails.assetId,
    }),
  );

  const {
    onTradeInputChange,
    onQuickSelectButtonClick,
    displayType,
    onInputBlur,
    inputValue,
    hasError,
    setHasError,
    onChangeDisplayType,
  } = useTradeInput({
    rootMaximumTradeAmount: currentTradeDetails.maximumTradeAmount,
    rootMinimumTradeAmount: currentTradeDetails.minimumTradeAmount,
    price: assetDetails.price,
    initialInputValue,
  });

  const onFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setLoading(true);

      setHasError((state) => ({ ...state, customError: null }));

      const formData = new FormData(e.currentTarget);

      let formattedAmount = (formData.get('amount') as string).replace(
        /,/g,
        '.',
      );

      const operation = formData.get('operation') as TradeOfferOperation;

      let amountFor: AmountFor = formData.get('amountFor') as AmountFor;

      const assetId = formData.get('assetId') as string;

      // Handle local storage
      if (amountFor === 'FIAT') {
        setInputValueToLocalStorage({ value: formattedAmount, assetId });
      } else {
        const fiatValue =
          valueFromAssetToFiat(formattedAmount, assetDetails.price)?.value ??
          '';

        setInputValueToLocalStorage({ value: fiatValue, assetId });
      }

      // Apply appropriateness check
      if (operation === 'BUY') {
        const canBuy = await applyAppropriatenessCheck({
          asset: {
            assetType: assetDetails.assetType,
            assetId: assetDetails.assetId,
          },
        });

        if (!canBuy) {
          setLoading(false);

          return;
        }
      }

      // Handle sell maximum exception
      if (
        operation === 'SELL' &&
        amountFor === 'FIAT' &&
        Number(formattedAmount) ===
          Number(currentTradeDetails.maximumTradeAmount.FIAT.value)
      ) {
        amountFor = 'ASSET';

        formattedAmount = currentTradeDetails.maximumTradeAmount.ASSET.value;
      }

      // Handle sell minimum exception
      if (
        operation === 'SELL' &&
        amountFor === 'ASSET' &&
        currentTradeDetails.minimumTradeAmount.ASSET?.value &&
        currentTradeDetails.minimumTradeAmount.FIAT?.value &&
        Number(formattedAmount) ===
          Number(currentTradeDetails.minimumTradeAmount.ASSET.value)
      ) {
        amountFor = 'FIAT';

        formattedAmount = currentTradeDetails.minimumTradeAmount.FIAT.value;
      }

      try {
        const resp = await initiateTrade({
          amount: formattedAmount,
          amountFor,
          assetId,
          operation,
        });

        if (resp.errors) {
          processErrors(resp.errors);

          setLoading(false);

          return;
        }

        const { createTradeOffer } = resp?.data ?? {};

        const { id: offerId } = createTradeOffer as unknown as { id: string };

        const deepLink = createTradeOffer?.deepLink;

        if (!createTradeOffer) {
          console.error('Missing offer data');

          router.push(`/error/generic`);

          return;
        }

        if (operation === 'BUY') {
          if (deepLink) {
            if (typeof window !== 'undefined') {
              const queryParams = new URLSearchParams(window.location.search);

              queryParams.set('verifyLink', deepLink);

              const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

              window.history.pushState({}, 'verifyLink', newUrl);
            }

            setLoading(false);
          } else if (offerId) {
            router.push(`/trade/buy/accept?tradeOfferID=${offerId}`);
          } else {
            console.error('Missing verification link and/or offer data');

            if (!isDevelopmentEnv()) {
              router.push(`/error/generic`);
            }
          }
        } else if (operation === 'SELL') {
          if (!offerId) {
            console.error('Missing offer data');
          }

          router.push(`/trade/sell/accept?tradeOfferID=${offerId}`);
        }
      } catch (err) {
        const stringedError = JSON.stringify(err);

        setLoading(false);

        setHasError((state) => ({ ...state, customError: stringedError }));

        console.error(stringedError);

        if (!isDevelopmentEnv()) {
          router.push(`/error/generic`);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetDetails, currentTradeDetails, router],
  );

  const inputPlaceholder = useMemo(() => {
    return getAmountInputPlaceHolder({
      currency: staticValues.currency,
      displayType: displayType,
      locale: staticValues.currentLocale,
      symbol: assetDetails.symbol as string,
    });
  }, [
    assetDetails.symbol,
    displayType,
    staticValues.currency,
    staticValues.currentLocale,
  ]);

  const formHasAnyErrors = hasError.tooHigh || hasError.tooLow;

  const allButtonsDisabled =
    currentTradeDetails.userCannotTrade || assetDetails.assetIsNotTradeable;

  const inputContextData = useMemo(
    () => ({
      currency: staticValues.currency,
      currentLocale: staticValues.currentLocale,
      displayType,
      symbol: assetDetails.symbol as string,
      value: inputValue,
      placeholder: inputPlaceholder,
    }),
    [
      staticValues.currency,
      staticValues.currentLocale,
      displayType,
      assetDetails.symbol,
      inputValue,
      inputPlaceholder,
    ],
  );

  const convertedSummary = useMemo(
    () =>
      getConvertedSummary({
        assetPrice: assetDetails.price,
        currency: staticValues.currency,
        displayType,
        locale: staticValues.currentLocale,
        symbol: assetDetails.symbol,
        translations: {
          inclSpread: staticValues.translations.inclSpread,
        },
        value: inputValue,
        maximumTradeAmount: currentTradeDetails.maximumTradeAmount,
        showInclSpread: true,
      }),
    [
      assetDetails.price,
      assetDetails.symbol,
      displayType,
      inputValue,
      staticValues,
      currentTradeDetails.maximumTradeAmount,
    ],
  );

  return {
    formState: {
      loading,
      displayType,
      inputValue,
      hasError,
      formHasAnyErrors,
      allButtonsDisabled,
      inputPlaceholder,
      inputContextData,
      convertedSummary,
    },
    handlers: {
      onFormSubmit,
      onTradeInputChange,
      onQuickSelectButtonClick,
      onInputBlur,
      onChangeDisplayType,
    },
  };
};
