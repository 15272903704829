'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { throttle } from '@bts-web/utils-functions';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';

interface CopyToClipboardProps {
  value: string;
  translations: {
    copy: string;
    successToastAddressTitle: string;
    addressCopiedFailed: string;
  };
}

export const CopyToClipboard = ({
  translations,
  value,
}: CopyToClipboardProps) => {
  const { copy, successToastAddressTitle, addressCopiedFailed } = translations;

  const { setAppNotification } = useAppNotification();

  const copyToClipboardAndShowNotification = async () => {
    try {
      await navigator.clipboard.writeText(value);

      setAppNotification({
        title: successToastAddressTitle,
        visual: 'success',
        customDuration: 3000,
      });
    } catch (error) {
      setAppNotification({
        title: addressCopiedFailed,
        visual: 'error',
        customDuration: 3000,
      });
    }
  };

  const handleCopyToClipboard = throttle(
    () => copyToClipboardAndShowNotification(),
    4000,
  );

  return (
    <ButtonBase
      aria-label={translations.copy}
      onClick={() => handleCopyToClipboard()}
      visual="ghost"
      size="small"
      noPadding
    >
      {copy}
      <Icon icon="copy" size="20" />
    </ButtonBase>
  );
};
