import { FC } from 'react';
import { TTwoFaCommonProps } from '../../types';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { AlertBox } from '../../../../../AlertBox/AlertBox';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { CopyToClipboardButton } from '../../../../../CopyToClipboardButton/CopyToClipboardButton';
import { css } from '@bts-web/utils-style-engine';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';

export type RecoveryCodesPageProps = Pick<
  TTwoFaCommonProps,
  'onContinue' | 'recoveryCodes'
>;

const RecoveryCodesPage: FC<RecoveryCodesPageProps> = ({
  onContinue,
  recoveryCodes,
}) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_recovery_subtitle')}`} />
      <TwoFaSectionDescription textContent={t('2fa_recovery_body')} />
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
        })}
      >
        <h4
          className={css({
            textAlign: 'left',
            fontSize: 'label.medium_medium',
          })}
        >
          {t('2fa_recovery_inputfield')}
        </h4>
        <textarea
          disabled
          value={recoveryCodes}
          className={css({
            width: '100%',
            height: '100px',
            resize: 'none',
            border: '1px solid',
            borderColor: 'neutrals.fill_tertiary',
            p: 'extra_small_3',
            minHeight: '106px',
            borderRadius: '8px',
          })}
        />
        <CopyToClipboardButton
          value={recoveryCodes}
          className={css({
            width: '100%',
            minHeight: '46px',
          })}
          ariaLabel="copy recovery codes"
        >
          {t('copy')}
        </CopyToClipboardButton>
      </div>
      <AlertBox variant="warning" children={t('2fa_recovery_warning')} />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          onClick={onContinue}
          visual="primary"
          size="medium"
          className={css({
            width: '100%',
            mt: 'extra_small_3',
          })}
        >
          {t('continue')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { RecoveryCodesPage };
