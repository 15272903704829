import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';

export interface TabBarItemProps {
  value: string;
  label: string;
  onClick: (itemValue: string) => void;
  parentValue?: string;
  disabled?: boolean;
}

export const TabBarItem: FC<TabBarItemProps> = ({
  value,
  label,
  onClick,
  disabled,
  parentValue,
}) => {
  const active = parentValue === value;

  return (
    <button
      onClick={() => {
        onClick(value);
      }}
      disabled={disabled}
      role="tab"
      aria-label={`${label} tab`}
      aria-selected={active}
      className={`${active ? 'active' : ''} ${css({
        position: 'relative',
        cursor: 'pointer',
        flex: 1,
        px: 'small',
        py: 'small',
        transition: 'all .25s',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'neutrals.text_secondary',
        border: '1px solid transparent',
        borderBottomColor: 'neutrals.stroke_secondary',
        borderLeftColor: 'neutrals.stroke_secondary',
        '&:last-of-type': {
          borderRightColor: 'neutrals.stroke_secondary',
        },

        '&.active': {
          color: 'neutrals.text_primary',
          fontWeight: '700',
          borderBottomColor: 'transparent !important',
          '&:last-of-type': {
            borderLeftColor: 'neutrals.stroke_secondary',
          },
        },
        '&:hover:not(:disabled)': {
          backgroundColor: 'neutrals.fill_focused',
        },
        '&:disabled': {
          userSelect: 'none',
          cursor: 'not-allowed',
          color: 'neutrals.text_disabled',
          borderBottomColor: 'neutrals.text_disabled',
          borderLeftColor: 'neutrals.text_disabled',
          '&:last-of-type': {
            borderRightColor: 'neutrals.text_disabled',
          },
        },
      })}`}
    >
      {active && (
        <span
          className={css({
            height: '5px',
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            right: '-1px',
            backgroundColor: 'neutrals.stroke_primary',
          })}
        />
      )}

      <span>{label}</span>
    </button>
  );
};
