'use client';

import { FC } from 'react';
import { CompassIcon } from '../../../common/svgIcons/CompassIcon/CompassIcon';
import { LeftNavMenuEntry } from './subcomponents/LeftNavMenuEntry';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@iconify-icon/react';

export type TranslatedValuesLeftNavType = {
  portfolioText: string;
  discoverText: string;
};

export const leftNavMenuStyles = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  alignItems: 'center',
  position: 'fixed',
  top: '72px',
  left: 0,
  bgColor: 'neutrals.card_fill_primary',
  height: 'calc(100vh - 72px)',
  width: '72px',
  py: '26px',
  px: 'extra_small_2',
  borderRight: '1px solid',
  borderColor: 'neutrals.stroke_secondary',
  gap: 'small',
};

const DesktopLeftNav: FC<{ translatedValues: TranslatedValuesLeftNavType }> = ({
  translatedValues,
}) => {
  const { discoverText, portfolioText } = translatedValues;

  return (
    <nav className={css(leftNavMenuStyles)}>
      <LeftNavMenuEntry
        url="/assets/portfolio"
        icon="portfolio"
        iconTheme="regular"
      >
        {portfolioText}
      </LeftNavMenuEntry>
      <LeftNavMenuEntry url="/assets/discover" IconComponent={CompassIcon}>
        {discoverText}
      </LeftNavMenuEntry>
    </nav>
  );
};

export { DesktopLeftNav };
