import {
  MaximumFractionDigits,
  PrecisionFloat,
  getDecimalsByDisplayType,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import { AmountFor } from '@bts-web/data-layer/server';
import { useCallback, useEffect, useState } from 'react';
import {
  AmountQuickSelectValuePassed,
  valueFromAssetToFiat,
  valueFromFiatToAsset,
} from '../../../../common';
import { validateAmountInput } from '../../../../common/utils/amountInputs/validateAmountInput';
import { MaximumTradeAmountType, MinimumTradeAmountType } from '../../types';

export type TradeInputErrorType = {
  tooHigh?: boolean;
  tooLow?: boolean;
  customError?: string | null;
};

export const useTradeInput = ({
  rootMaximumTradeAmount,
  rootMinimumTradeAmount,
  price,
  initialInputValue,
}: {
  rootMaximumTradeAmount: MaximumTradeAmountType;
  rootMinimumTradeAmount: MinimumTradeAmountType;
  price: PrecisionFloat;
  initialInputValue?: string;
}) => {
  const [preferredAssetPrecision, setPreferredAssetPrecision] =
    useState<number>(MaximumFractionDigits.ASSET);

  const [displayType, setDisplayType] = useState<AmountFor>('FIAT');

  const maximumTradeAmount = rootMaximumTradeAmount[displayType];

  const minimumTradeAmount = rootMinimumTradeAmount[displayType];

  const [hasError, setHasError] = useState<TradeInputErrorType>({
    tooHigh: false,
    tooLow: false,
    customError: null,
  });

  const [inputValue, setInputValue] = useState<string>(initialInputValue ?? '');

  const onQuickSelectButtonClick = (value: AmountQuickSelectValuePassed) => {
    const newValuePrecisionFloat = value[displayType];

    setInputValue(getPrecisionFloatValue(newValuePrecisionFloat));
  };

  const onChangeDisplayType = useCallback(
    (newType: AmountFor) => {
      // bind and convert only if there's a number there, otherwise it's filled with zeroes

      if (inputValue.length > 0) {
        let newValue = '';

        const previousDisplayType = newType === 'FIAT' ? 'ASSET' : 'FIAT';

        const wasMax =
          inputValue === rootMaximumTradeAmount[previousDisplayType]?.value;

        // TODO: possible backend calculation bug, for the value of maximum that we receive?
        if (wasMax) {
          newValue = rootMaximumTradeAmount[newType]?.value;
        } else {
          newValue =
            (newType === 'FIAT'
              ? valueFromAssetToFiat(inputValue, price)
              : valueFromFiatToAsset(inputValue, price, preferredAssetPrecision)
            )?.value ?? '0';
        }

        setInputValue(newValue ?? '');
      }

      setDisplayType(newType);
    },
    [inputValue, preferredAssetPrecision, price, rootMaximumTradeAmount],
  );

  const onTradeInputChange = (newValue: string) => {
    const isValid = validateAmountInput(newValue, displayType);

    if (!isValid) return;

    setInputValue(newValue);
  };

  const onInputBlur = (value: string) => {
    return;
  };

  // Error binding and parsing
  useEffect(() => {
    if (!inputValue) {
      setHasError((state) => ({
        ...state,
        tooHigh: false,
        tooLow: false,
      }));

      return;
    }

    const cleanedNumberDecimalsPreserved = Number(
      inputValue.replace(/[^0-9]/g, '.'),
    );

    // preserve the user's preferred decimal if expressed
    if (displayType === 'ASSET' && cleanedNumberDecimalsPreserved > 0) {
      // user might bind a custom precision when using asset view
      const parts = cleanedNumberDecimalsPreserved.toString().split('.');

      const precision = parts[1]
        ? parts[1].length
        : MaximumFractionDigits.ASSET;

      setPreferredAssetPrecision(precision);
    }

    const decimalsByDisplayType = getDecimalsByDisplayType(displayType);

    // replace anything not a number or a dot with a dot
    const currentTradeAmount = Number(
      cleanedNumberDecimalsPreserved.toFixed(decimalsByDisplayType),
    );

    let isTooHigh = false;

    let isTooLow = false;

    const maxTradeValue = Number(getPrecisionFloatValue(maximumTradeAmount));

    const minTradeValue = Number(getPrecisionFloatValue(minimumTradeAmount));

    if (maximumTradeAmount && currentTradeAmount > maxTradeValue) {
      isTooHigh = true;
    }

    if (
      (minimumTradeAmount &&
        currentTradeAmount < minTradeValue &&
        currentTradeAmount > 0) ||
      currentTradeAmount === 0
    ) {
      isTooLow = true;
    }

    setHasError((state) => ({
      ...state,
      tooHigh: isTooHigh,
      tooLow: isTooLow,
    }));
  }, [inputValue, maximumTradeAmount, minimumTradeAmount, displayType]);

  return {
    onTradeInputChange,
    onQuickSelectButtonClick,
    minimumTradeAmount,
    displayType,
    setHasError,
    hasError,
    inputValue,
    onInputBlur,
    onChangeDisplayType,
  };
};
