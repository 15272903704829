'use client';

import { FC } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { TwoFaCodeSubmitBox } from './subcomponents/TwoFaCodeSubmitBox';
import { TTwoFaCommonProps } from '../../types';
import { useClientTranslation } from '@bts-web/utils-lokalise';

const InputCodePage: FC<
  Pick<TTwoFaCommonProps, 'onContinue' | 'bindRecoveryCodes'>
> = ({ onContinue, bindRecoveryCodes }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={t('corp_onboarding_email_q_title')} />
      <TwoFaSectionDescription textContent={t('authentication_text')} />
      <TwoFaCodeSubmitBox
        bindRecoveryCodes={bindRecoveryCodes}
        onContinue={onContinue}
      />
    </>
  );
};

export { InputCodePage };
