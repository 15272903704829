export enum DeleteTwoFaModalStep {
  INITIAL = 'initial',
  METHOD_SELECTION = 'methodSelection',
  METHOD_AUTHENTICATOR = 'methodAuthenticator',
  METHOD_RECOVERY = 'methodRecovery',
  CONFIRMATION = 'confirmation',
}

export type DeleteTwoFaOnDelete = () => void;

export type DeleteTwoFaOnContinue = () => void;

export type DeleteTwoFaOnClose = () => void;
