import { FC } from 'react';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';
import { UserTopBarMenu } from '../DesktopTopHeaderBar/subcomponents/UserTopBarMenu/UserTopBarMenu';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';

const { t } = initServerTranslation();

const MobileProfileMenu: FC = async () => {
  await updateServerLanguage();

  const translations = {
    frequently_asked_questions: t('frequently_asked_questions'),
    customer_support: t('customer_support'),
    terms_and_conditions: t('terms_and_conditions'),
    general_logout: t('general_logout'),
    profile: t('general_profile'),
    twoFa_welcome_title: t('2fa_welcome_title'),
  };

  return (
    <Drawer
      id="mobile-profile-drawer"
      position="bottom"
      buttonTriggerNode={
        <button>
          <Icon icon="person" size="20" theme="regular" />
        </button>
      }
      contentNode={
        <DrawerContent title={translations.profile} ariaLabel="profile menu">
          <UserTopBarMenu translatedValues={translations} />
        </DrawerContent>
      }
    />
  );
};

export { MobileProfileMenu };
