import { css } from '@bts-web/utils-style-engine';
import { BitPandaLogoIcon } from '../../common/svgIcons/BitPandaLogoIcon/BitPandaLogoIcon';
import { HiddenMobile } from '../LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';

const containerStyles = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 'small',
  overflow: 'auto',
  lg: {
    backgroundColor: 'neutrals.widget_primary',
    gap: 'large',
    paddingY: 'extra_large',
    alignItems: 'center',
  },
});

const contentWrapperStyles = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',

  lg: {
    backgroundColor: 'white',
    border: '1px solid #eaeaea',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    gap: 'small',
    height: 'auto',
    width: '520px',
  },
});

interface ResponsiveLayoutWithLogoProps extends React.PropsWithChildren {
  ariaLabel?: string;
}

const ResponsiveLayoutWithLogo = ({
  children,
  ariaLabel,
}: ResponsiveLayoutWithLogoProps) => {
  return (
    <div aria-label={ariaLabel} className={containerStyles}>
      <HiddenMobile>
        <BitPandaLogoIcon size={25} />
      </HiddenMobile>

      <div className={contentWrapperStyles}>{children}</div>
    </div>
  );
};

export { ResponsiveLayoutWithLogo };
