'use client';

import { redirect, useRouter } from 'next/navigation';
import { FC, useCallback, useMemo, useState, ReactNode } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { Icon } from '@bts-web/design-system/component/icon';
import { getAppConfig } from '@bts-web/core-app-config';
import { AmountFor } from '@bts-web/data-layer/server';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import { isBpfsAsset } from '../../utils/isBpfsAsset';
import {
  AssetType,
  submitButtonWrapperAndButtonProperStyles,
} from '../../utils';
import { OfferLoaderElement } from '../../Reusable/OfferLoaderElement/OfferLoaderElement';
import { PoweredByText } from '../../Reusable/PoweredByText/PoweredByText';
import { TradeTaxSummary } from '../../Reusable/TradeTaxSummary/TradeTaxSummary';
import { AcceptBuyTradeDisclaimerWrapper } from './subcomponents/AcceptBuyTradeDisclaimerWrapper/AcceptBuyTradeDisclaimerWrapper';
import { finishTradeRedirectBuilder } from '../../FinishTrade/actions/finishTradeRedirectBuilder.action';
import { TradeAssetInfoDisplay } from '../../../common/components/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { TradeScreenTranslations } from '../../utils/getTradeTranslations';
import {
  AcceptTradeControllerVariants,
  TradeOfferDataNonNullable,
} from '../types';
import { AmountInputWrapper, ButtonBase } from '../../../common';
import { acceptTradeOffer, fetchOfferData } from '../../../common/gqlActions';
import { BelowInputInfoTable } from '../../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { getConvertedSummary } from '../../TradeParent/utils/getConvertedSummary';
import { removeInputValueFromLocalStorage } from '../../TradeParent/utils/localStorageInputValueUtils';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';
import { getMaximumTradeAmount } from '../../TradeParent/utils';
import { datadogErrorHelper } from '../../../common';

const minimumRefreshTime = 5000;

export type AcceptTradeSubscriptionWrapperProps = {
  initialOfferData: TradeOfferDataNonNullable;
  offerId: string;
  translations: TradeScreenTranslations;
  children?: ReactNode;
  variant: AcceptTradeControllerVariants;
  currentLocale: string;
  currency: string;
};

export const AcceptTradeSubscriptionWrapper: FC<
  AcceptTradeSubscriptionWrapperProps
> = ({
  initialOfferData,
  offerId,
  translations,
  children,
  variant,
  currentLocale,
  currency,
}) => {
  const { setAppNotification } = useAppNotification();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const router = useRouter();

  const [tradeOfferData, setTradeOfferData] =
    useState<TradeOfferDataNonNullable>(initialOfferData);

  const [nextExpiresAt, setNextExpiresAt] = useState(
    initialOfferData.expiresAt,
  );

  const [isTradeOfferViewQueryRefetching, setIsTradeOfferViewQueryRefetching] =
    useState(false);

  const [isOfferAccepted, setOfferAccepted] = useState(false);

  const offerAvailabilityInMs = useMemo(() => {
    if (!nextExpiresAt) return minimumRefreshTime; // Default to 5 seconds if no expiry is set

    const nextExpiresAtInMs = new Date(nextExpiresAt).getTime();

    const currentTimeInMs = Date.now();

    const newValue = nextExpiresAtInMs - currentTimeInMs;

    return Math.max(newValue, minimumRefreshTime); // Ensure a minimum of 5 seconds
  }, [nextExpiresAt]);

  const refetchTradeOfferViewQuery = useCallback(async () => {
    if (isTradeOfferViewQueryRefetching || isOfferAccepted) return;

    setIsTradeOfferViewQueryRefetching(true);

    try {
      const response = await fetchOfferData(offerId);

      const tradeOffer = response.data.tradeOffer;

      if (!tradeOffer) {
        console.warn('%cNo trade offer data found', 'color:red');

        if (!isDevelopmentEnv()) {
          router.push('/error/server');
        }
      } else {
        setTradeOfferData(tradeOffer);

        setNextExpiresAt(tradeOffer.expiresAt);

        setAppNotification({
          title: translations.offerExpiredTitle,
          subtitle: translations.offerExpiredSubtitle,
          withClose: true,
          visual: 'info',
          customDuration: 2000,
        });

        document.dispatchEvent(new Event('onTradeOfferRefetched'));
      }
    } catch (e) {
      console.error('Error fetching trade offer:', e);

      if (!isDevelopmentEnv()) {
        router.push('/error/server');
      }
    } finally {
      setIsTradeOfferViewQueryRefetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offerId,
    isOfferAccepted,
    isDevelopmentEnv,
    router,
    setAppNotification,
    translations,
  ]);

  const handleSpinnerComplete = useCallback(() => {
    refetchTradeOfferViewQuery();
  }, [refetchTradeOfferViewQuery]);

  const { additionalAcceptInputTradeInfo } = getAppConfig();

  const { asset, totalAmount, taxAmount, fiatAmount, hasTaxOfflineWarning } =
    tradeOfferData;

  const {
    logoUrl,
    name,
    priceBuy,
    priceSell,
    symbol: initialSymbol,
    __typename,
  } = asset;

  const price = variant === 'buy' ? priceBuy : priceSell;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const defaultDisplayType: AmountFor = 'FIAT';

  const totalSum = getPrecisionFloatValue(totalAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const loading = isTradeOfferViewQueryRefetching;

  const shouldShowTaxSummary =
    taxAmount && Number(taxAmount?.value) > 0 && fiatAmount?.value;

  const triggerAcceptTradeOffer = async () => {
    setIsTradeOfferViewQueryRefetching(true);

    setOfferAccepted(true);

    try {
      const offerResponse = await acceptTradeOffer(offerId, variant);

      if (offerResponse.errors) {
        processErrors(offerResponse.errors);
      }

      const statusResponse = offerResponse.data.acceptTradeOffer?.status;

      if (!statusResponse) {
        throw new Error('No status response from accept trade offer');
      }

      removeInputValueFromLocalStorage();

      await finishTradeRedirectBuilder(
        variant,
        offerId,
        statusResponse,
        !!hasTaxOfflineWarning,
      );
    } catch (error) {
      console.error('Error accepting trade offer:', error);

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });

      if (!isDevelopmentEnv()) {
        redirect(`/trade/${variant.toLowerCase()}/failure`);
      }

      setAppNotification({
        title: translations.offerExpiredTitle,
        subtitle: translations.offerExpiredSubtitle,
        withClose: true,
        visual: 'error',
      });
    }
    // Do not remove the loading state as after the operation is completed, the duration until the redirect is not known, and the user could submit multiple times
    // finally {
    //   setIsTradeOfferViewQueryRefetching(false);

    //   setOfferAccepted(false);
    // }
  };

  const maximumTradeAmount = getMaximumTradeAmount({
    fiatBalance: asset.portfolio?.fiatBalance,
    assetBalance: asset.portfolio?.assetBalance,
    type: variant,
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
      })}
    >
      <TradeAssetInfoDisplay
        assetData={{ logoUrl, name }}
        currentLocale={currentLocale}
        price={price}
        symbol={symbol}
        loaderElement={
          <OfferLoaderElement
            key={nextExpiresAt}
            duration={offerAvailabilityInMs}
            size={20}
            onComplete={handleSpinnerComplete}
          />
        }
      />
      {children}

      <AmountInputWrapper
        inputContextData={{
          symbol: asset.symbol ?? '',
          currency,
          currentLocale: currentLocale,
          displayType: defaultDisplayType,
          value: totalSum,
          placeholder: undefined,
        }}
      />
      <div
        className={css({
          pt: 'extra_small',
          pb: 'medium',
          borderBottom: '1px solid',
          borderColor: 'neutrals.divider',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: translations['units'],
              value: getConvertedSummary({
                assetPrice: price,
                currency: currency,
                displayType: defaultDisplayType,
                locale: currentLocale,
                symbol: symbol,
                translations: { inclSpread: translations.inclSpread },
                value: totalSum,
                useRawSource: {
                  rawSourceDisplayType: 'ASSET',
                  rawSourceValue: tradeOfferData?.assetAmount as PrecisionFloat,
                },
                maximumTradeAmount,
                showInclSpread: true,
              }),
            },
            ...(additionalAcceptInputTradeInfo
              ? additionalAcceptInputTradeInfo.map((item) => ({
                  title:
                    translations[
                      item.titleKey as keyof TradeScreenTranslations
                    ],
                  value:
                    translations[
                      item.valueKey as keyof TradeScreenTranslations
                    ],
                }))
              : []),
          ]}
        />
      </div>
      <div
        className={css({
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 'medium',
          gap: 'extra_small',
          marginTop: 'auto',
        })}
      >
        {shouldShowTaxSummary ? (
          <TradeTaxSummary
            currency={currency}
            currentLocale={currentLocale}
            fiatAmount={fiatAmount}
            isTaxServiceOffline={!!hasTaxOfflineWarning}
            taxAmount={taxAmount}
            translations={translations}
          />
        ) : (
          // ensures that this is bound to the bottom of the page
          <div className={css({ mt: 'auto' })} />
        )}
        <AcceptBuyTradeDisclaimerWrapper
          translations={translations}
          variant={variant}
          legalUrl={asset.legalUrl ?? ''}
          assetType={asset.__typename as AssetType}
        >
          {({ areTermsAgreedTo, isDisclaimerLoading }) => {
            const isLoading = isDisclaimerLoading || loading;

            const isDisabled = !areTermsAgreedTo || isLoading;

            return (
              <>
                <PoweredByText {...poweredByText} />
                <div className={submitButtonWrapperAndButtonProperStyles}>
                  <ButtonBase
                    visual="accent"
                    size="large"
                    disabled={isDisabled}
                    onClick={triggerAcceptTradeOffer}
                    fullWidth
                  >
                    {variant === 'buy'
                      ? translations.buyNow
                      : translations.sellNow}
                    {isLoading && (
                      <Icon
                        data-element="loading-icon"
                        theme="filled"
                        size={'20'}
                        icon="spinner-ios"
                        aria-label="loading icon"
                      />
                    )}
                  </ButtonBase>
                </div>
              </>
            );
          }}
        </AcceptBuyTradeDisclaimerWrapper>
      </div>
    </div>
  );
};
