import { useCallback, useMemo, useState } from 'react';
import { DeleteTwoFaModalStep } from './types';

export const useDeleteTwoFactorAuthState = () => {
  const [currentPage, setPage] = useState<DeleteTwoFaModalStep>(
    DeleteTwoFaModalStep.INITIAL,
  );

  const onContinue = useCallback(() => {
    switch (currentPage) {
      case DeleteTwoFaModalStep.INITIAL:
        setPage(DeleteTwoFaModalStep.METHOD_SELECTION);

        break;

      case DeleteTwoFaModalStep.METHOD_AUTHENTICATOR:
        setPage(DeleteTwoFaModalStep.CONFIRMATION);

        break;

      case DeleteTwoFaModalStep.METHOD_RECOVERY:
        setPage(DeleteTwoFaModalStep.CONFIRMATION);

        break;

      default:
        break;
    }
  }, [currentPage]);

  const currentStepNumber = useMemo(() => {
    switch (currentPage) {
      case DeleteTwoFaModalStep.INITIAL:
        return 0;

      case DeleteTwoFaModalStep.METHOD_SELECTION:
        return 1;

      case DeleteTwoFaModalStep.METHOD_RECOVERY:
        return 2;

      case DeleteTwoFaModalStep.METHOD_AUTHENTICATOR:
        return 2;

      case DeleteTwoFaModalStep.CONFIRMATION:
        return 3;

      default:
        return 0;
    }
  }, [currentPage]);

  return { onContinue, currentPage, currentStepNumber, setPage };
};
