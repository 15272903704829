export const collection = {
  "prefix": "fluent",
  "icons": {
    "alert-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-10-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-12-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-16-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-20-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-24-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-28-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-32-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-48-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "copy-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "deposits-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-10-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-12-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-16-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-20-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-24-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-28-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-32-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-48-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-10-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-12-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-16-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-20-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-24-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-28-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-32-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-48-filled": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spinner-ios-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "stake-wip-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "virtual-network-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "wallet-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "warning-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "withdraw-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-down-primary-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-right-gray-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "delisting-10-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-12-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-16-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-20-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-24-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-28-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-32-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-48-filled": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "gift-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "logout-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-10-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-12-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-16-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-20-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-24-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-28-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-32-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-48-filled": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "toggle-10-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-12-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-16-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-20-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-24-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-28-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-32-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-48-filled": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "up-down-arrows-10-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-12-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-16-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-20-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-24-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-28-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-32-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-48-filled": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "alert-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.75 2.75a.75.75 0 0 0-1.5 0v.877c-2.207.174-3.772 1.112-4.8 2.296C5.334 7.21 4.871 8.762 4.871 9.86v8.247H4.25a.75.75 0 0 0 0 1.5h5.405v.207C9.655 21.094 10.781 22 12 22s2.345-.907 2.345-2.186v-.207h5.405a.75.75 0 0 0 0-1.5h-.62V9.86c0-1.098-.464-2.651-1.58-3.937-1.028-1.184-2.593-2.122-4.8-2.296zm4.88 15.357V9.86c0-.74-.335-1.943-1.213-2.953-.853-.983-2.24-1.809-4.417-1.809s-3.564.826-4.417 1.809C6.705 7.917 6.37 9.12 6.37 9.86v8.247zm-6.475 1.707v-.207h1.69v.207c0 .307-.303.686-.845.686s-.845-.38-.845-.686\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-sort-down-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"m11.65 4.007.1-.007a.75.75 0 0 1 .744.648l.007.102-.001 12.696 3.22-3.221a.75.75 0 0 1 .976-.073l.084.072a.75.75 0 0 1 .073.977l-.072.084-4.497 4.5a.75.75 0 0 1-.976.073l-.084-.073-4.504-4.5a.75.75 0 0 1 .976-1.133l.084.072L11 17.442V4.75a.75.75 0 0 1 .65-.743l.1-.007z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "best-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.87 9.273a.25.25 0 0 0 .246-.206l1.047-5.861A.25.25 0 0 1 9.409 3h5.18c1.951 0 3.553.582 4.663 1.568C20.36 5.551 21 6.955 21 8.654c0 1.094-.32 2.107-.885 2.876l-.265.327a.25.25 0 0 0 .005.322l.043.049c.688.792 1.086 1.908 1.086 3.144v.001c.008 1.637-.589 3-1.628 3.975-1.04.977-2.548 1.583-4.39 1.655l-.38.008-8.317-.014a.25.25 0 0 1-.246-.295l1.016-5.67a.25.25 0 0 0-.246-.294H3.3a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205h3.676a.25.25 0 0 0 .246-.207l.354-2.01a.25.25 0 0 0-.246-.294H4.016a.25.25 0 0 1-.246-.295l.178-.977a.25.25 0 0 1 .246-.205zm6.726-4.812h-3.915a.25.25 0 0 0-.246.206L9.66 8.963a.25.25 0 0 0 .246.295l2.451.005h.002c3.572.037 5.136.294 6.383.982l.074.04a.25.25 0 0 0 .349-.117l.034-.077c.195-.436.278-.945.278-1.468 0-1.235-.425-2.258-1.228-2.983-.797-.72-1.935-1.115-3.31-1.172zm-2.78 6.29-2.255-.001a.25.25 0 0 0-.246.204l-.371 1.996a.25.25 0 0 0 .246.296l2.63-.001 1.365-.015h.003c1.538-.03 2.637-.108 3.435-.257s1.335-.377 1.71-.74l.072-.068a.25.25 0 0 0-.04-.392l-.296-.185-.01-.006c-.994-.58-2.368-.813-6.241-.83zm7.401 3.205-.034-.082a.25.25 0 0 0-.36-.117l-.076.046c-1.147.688-2.898.9-6.377.93h-.002l-3.523.005a.25.25 0 0 0-.246.205l-.775 4.265a.25.25 0 0 0 .245.295l6.52.016c1.463 0 2.685-.383 3.548-1.109.869-.732 1.34-1.786 1.34-3.053 0-.495-.084-.984-.26-1.401\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-10-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-12-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-16-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-20-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-24-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-28-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-32-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "buy-48-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2c-.483 0-.964.083-1.415.245a3.9 3.9 0 0 0-1.221.71c-.355.308-.643.68-.841 1.096a3.1 3.1 0 0 0-.303 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.052-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5c-.31 0-.617.053-.9.155a2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 11.5a.753.753 0 0 0-.756-.75.753.753 0 0 0-.756.75v3.708L9.507 13.51a.76.76 0 0 0-1.07.007.746.746 0 0 0 .008 1.061l3.025 2.957a.76.76 0 0 0 1.061 0l3.024-2.957a.746.746 0 0 0 .008-1.061.76.76 0 0 0-1.07-.008l-1.737 1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "circle-placeholder-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.5 0a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "communication-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M12.75 12.702a.75.75 0 0 1-.75.746.75.75 0 0 1-.75-.746.749.749 0 0 1 1.5 0\"/><path fill-rule=\"evenodd\" d=\"M5.985 4.751a10.03 10.03 0 0 1 6.016-2.001c2.17 0 4.282.703 6.016 2.003a9.95 9.95 0 0 1 3.594 5.202c.6 2.076.504 4.29-.273 6.307a9.96 9.96 0 0 1-4.03 4.874.75.75 0 0 1-1.034-.236.744.744 0 0 1 .238-1.03 8.47 8.47 0 0 0 3.426-4.143 8.42 8.42 0 0 0 .232-5.36 8.46 8.46 0 0 0-3.056-4.422 8.53 8.53 0 0 0-10.227-.001 8.46 8.46 0 0 0-3.056 4.42 8.42 8.42 0 0 0 .23 5.361 8.47 8.47 0 0 0 3.426 4.145c.35.219.457.68.237 1.028a.75.75 0 0 1-1.034.237 9.96 9.96 0 0 1-4.03-4.876 9.9 9.9 0 0 1-.27-6.307 9.95 9.95 0 0 1 3.595-5.2\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.24 7.733a6.27 6.27 0 0 1 7.52 0 6.22 6.22 0 0 1 2.247 3.252 6.2 6.2 0 0 1-.17 3.942 6.23 6.23 0 0 1-2.52 3.046.75.75 0 0 1-1.033-.236.744.744 0 0 1 .237-1.029 4.73 4.73 0 0 0 1.915-2.315 4.7 4.7 0 0 0 .13-2.996 4.73 4.73 0 0 0-1.708-2.47 4.765 4.765 0 0 0-5.715-.002 4.73 4.73 0 0 0-1.708 2.47 4.7 4.7 0 0 0 .129 2.997 4.73 4.73 0 0 0 1.914 2.315c.35.22.457.68.237 1.03a.75.75 0 0 1-1.034.235 6.23 6.23 0 0 1-2.518-3.047 6.2 6.2 0 0 1-.17-3.942 6.22 6.22 0 0 1 2.248-3.25\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "copy-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "copy-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM7 4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1zM4 6a2 2 0 0 1 1-1.732V14.5A2.5 2.5 0 0 0 7.5 17h6.232A2 2 0 0 1 12 18H7.5A3.5 3.5 0 0 1 4 14.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "deposits-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposits-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "discover-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M14.588 2.34A10 10 0 0 0 2.341 14.589.75.75 0 0 0 3.79 14.2 8.5 8.5 0 0 1 14.2 3.79a.75.75 0 1 0 .388-1.45M12 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5\"/><path fill-rule=\"evenodd\" d=\"M21.362 4.367c.732-1.132-.597-2.46-1.729-1.729L9.54 9.17a1.25 1.25 0 0 0-.37.37L2.638 19.635c-.732 1.131.597 2.46 1.728 1.728l10.094-6.531c.148-.096.274-.222.37-.37zM10.399 10.4l9.07-5.869-5.869 9.07-9.07 5.868z\" clip-rule=\"evenodd\"/><path d=\"M19.071 19.071a10 10 0 0 1-9.66 2.588.75.75 0 0 1 .389-1.449A8.502 8.502 0 0 0 20.21 9.8a.75.75 0 1 1 1.45-.388 10 10 0 0 1-2.589 9.66\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "earnings-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M15.356 10.138A6.748 6.748 0 0 0 8.75 2 6.75 6.75 0 0 0 2 8.746 6.74 6.74 0 0 0 5.394 14.6a6.8 6.8 0 0 0-.144 1.392c0 1.733.654 3.314 1.73 4.509H3.5A.75.75 0 1 0 3.5 22h17a.75.75 0 1 0 0-1.499h-3.48a6.742 6.742 0 0 0-1.664-10.363m-.67 10.363A5.248 5.248 0 0 0 12 10.745a5.248 5.248 0 0 0-2.686 9.756zM8.75 3.499a5.248 5.248 0 0 0-2.869 9.641A6.75 6.75 0 0 1 12 9.246a6.8 6.8 0 0 1 1.942.283A5.248 5.248 0 0 0 8.75 3.5\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "facebook-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M21.58 2H2.42a.42.42 0 0 0-.42.42v19.16c0 .232.188.42.42.42H12.6v-6.683h-2.15a.336.336 0 0 1-.336-.336v-2.71c0-.185.15-.336.336-.336h2.15v-1.87c0-2.463 1.551-4.055 3.952-4.055l2.138.003c.185 0 .335.15.335.336v2.516c0 .185-.15.336-.336.336h-1.44c-.992 0-1.173.387-1.173 1.15v1.584h2.513a.336.336 0 0 1 .336.337l-.001 2.709c0 .186-.15.336-.336.336h-2.513V22h5.505a.42.42 0 0 0 .42-.42V2.42a.42.42 0 0 0-.42-.42\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "funds-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.929 3.831 19.07 7.35a2.257 2.257 0 0 1 2.18 2.26v2.74c.453.318.75.847.75 1.445v1.006c0 .598-.297 1.127-.75 1.445v2.74A2.257 2.257 0 0 1 19 21.25H4.25A2.257 2.257 0 0 1 2 18.987V9.61a2.257 2.257 0 0 1 2.25-2.263h2.668l6.917-4.264a2.24 2.24 0 0 1 3.094.747m.383 3.517H9.789l4.83-2.978a.747.747 0 0 1 1.03.25zm2.438 9.214h-2c-.966 0-1.75-.788-1.75-1.76v-1.006c0-.972.784-1.76 1.75-1.76h2V9.61a.75.75 0 0 0-.75-.754H4.25a.75.75 0 0 0-.75.754v9.376c0 .416.336.754.75.754H19c.414 0 .75-.338.75-.754zm-2.25-2.766a.25.25 0 0 1 .25-.252h2.5a.25.25 0 0 1 .25.252v1.006a.25.25 0 0 1-.25.251h-2.5a.25.25 0 0 1-.25-.251z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "home-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.446 3.649a.75.75 0 0 0-.892 0l-7.75 5.744a.75.75 0 0 0-.304.603v9.752c0 .415.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V9.996a.75.75 0 0 0-.304-.603zm-1.785-1.207a2.25 2.25 0 0 1 2.678 0l7.75 5.744c.573.425.911 1.096.911 1.81v9.752A2.25 2.25 0 0 1 19.75 22H4.25A2.25 2.25 0 0 1 2 19.748V9.996c0-.714.338-1.385.91-1.81z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "info-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12 1.998c5.524 0 10.002 4.478 10.002 10.002 0 5.523-4.478 10-10.002 10S2 17.523 2 12C1.999 6.476 6.476 1.998 12 1.998m0 1.5a8.502 8.502 0 1 0 0 17.003 8.502 8.502 0 0 0 0-17.003m-.004 7a.75.75 0 0 1 .744.648l.007.102.004 5.502a.75.75 0 0 1-1.494.102l-.006-.101-.004-5.502a.75.75 0 0 1 .75-.75m.005-3.497a.999.999 0 1 1 0 1.997.999.999 0 0 1 0-1.997\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "instagram-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M8.667 12a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0m-1.802 0a5.135 5.135 0 1 0 10.27 0 5.135 5.135 0 0 0-10.27 0m9.274-5.338a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0M7.959 20.14c-.974-.045-1.504-.207-1.856-.344a3.1 3.1 0 0 1-1.15-.748 3.1 3.1 0 0 1-.749-1.15c-.137-.352-.3-.882-.344-1.857-.048-1.054-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.041.045-.975.208-1.504.344-1.857.182-.467.399-.8.748-1.15a3.1 3.1 0 0 1 1.15-.748c.353-.138.883-.3 1.858-.345 1.054-.048 1.37-.058 4.04-.058s2.987.01 4.042.058c.975.045 1.504.208 1.857.345.467.18.8.398 1.15.748s.566.683.748 1.15c.137.352.3.882.344 1.857.049 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.041-.044.975-.207 1.505-.344 1.857-.182.467-.398.8-.748 1.15s-.683.566-1.15.748c-.352.137-.882.3-1.857.344-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058M7.877 2.06c-1.064.049-1.791.218-2.427.465a4.9 4.9 0 0 0-1.77 1.154 4.9 4.9 0 0 0-1.154 1.77c-.247.637-.416 1.364-.464 2.428C2.01 8.943 2 9.284 2 12s.011 3.057.06 4.123c.049 1.064.218 1.791.465 2.427a4.9 4.9 0 0 0 1.154 1.771 4.9 4.9 0 0 0 1.771 1.154c.637.247 1.363.416 2.427.464C8.945 21.988 9.285 22 12 22c2.717 0 3.057-.011 4.123-.06 1.065-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154 4.9 4.9 0 0 0 1.154-1.77c.247-.637.417-1.364.464-2.428.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123c-.048-1.064-.217-1.791-.464-2.427a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.154c-.637-.247-1.364-.417-2.428-.464C15.058 2.012 14.717 2 12.001 2s-3.056.011-4.124.06\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "learn-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M18.33 5.424c-1.177-.35-2.404-.547-3.307-.328-.743.18-1.74.753-2.273 1.084v12.29c.577-.315 1.288-.652 1.92-.806 1.203-.292 2.607-.061 3.765.256.786.215 1.51.485 2.065.715V6.244a17 17 0 0 0-2.17-.82M11.25 18.47c-.577-.315-1.288-.652-1.92-.806-1.203-.292-2.607-.061-3.765.256-.786.215-1.51.485-2.065.715V6.244c.46-.21 1.26-.548 2.17-.82 1.177-.35 2.404-.547 3.307-.328.743.18 1.74.753 2.273 1.084zm7.508-14.488c1.256.375 2.31.862 2.683 1.042a.99.99 0 0 1 .559.898v13.467a1.01 1.01 0 0 1-1.431.916 17 17 0 0 0-2.53-.934c-1.095-.3-2.194-.444-3.016-.244-.853.207-2.041.93-2.477 1.212-.333.215-.76.215-1.092 0-.436-.281-1.624-1.005-2.477-1.212-.822-.2-1.921-.056-3.017.244a17 17 0 0 0-2.529.934A1.01 1.01 0 0 1 2 19.39V5.922c0-.367.2-.724.56-.898.372-.18 1.426-.667 2.682-1.041 1.227-.366 2.779-.667 4.088-.349.954.232 2.09.883 2.67 1.242.58-.359 1.715-1.01 2.67-1.242 1.309-.318 2.861-.017 4.088.348\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "lock-shield-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2a4 4 0 0 1 4 4v2h1.75A2.25 2.25 0 0 1 18 10.25V11c-.319 0-.637.11-.896.329l-.107.1q-.247.256-.496.457L16.5 10.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h9.888a6 6 0 0 0 1.54 1.5H4.25A2.25 2.25 0 0 1 2 19.75v-9.5A2.25 2.25 0 0 1 4.25 8H6V6a4 4 0 0 1 4-4m8.284 10.122c.992 1.036 2.091 1.545 3.316 1.545.193 0 .355.143.392.332l.008.084v2.501c0 2.682-1.313 4.506-3.873 5.395a.4.4 0 0 1-.253 0c-2.476-.86-3.785-2.592-3.87-5.13L14 16.585v-2.5c0-.23.18-.417.4-.417 1.223 0 2.323-.51 3.318-1.545a.39.39 0 0 1 .566 0M10 13.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-10A2.5 2.5 0 0 0 7.5 6v2h5V6A2.5 2.5 0 0 0 10 3.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-10-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-12-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-16-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-20-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-24-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-28-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-32-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "portfolio-48-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M14.423 2a1.75 1.75 0 0 0-1.75 1.75v5.827c0 .966.783 1.75 1.75 1.75h5.827A1.75 1.75 0 0 0 22 9.577V4.25A2.25 2.25 0 0 0 19.75 2zm-.25 1.75a.25.25 0 0 1 .25-.25h5.327a.75.75 0 0 1 .75.75v5.327a.25.25 0 0 1-.25.25h-5.827a.25.25 0 0 1-.25-.25z\"/><path d=\"M4.25 4.846A2.25 2.25 0 0 0 2 7.096V19.75A2.25 2.25 0 0 0 4.25 22h12.654a2.25 2.25 0 0 0 2.25-2.25v-5.327a1.75 1.75 0 0 0-1.75-1.75h-6.077V6.596a1.75 1.75 0 0 0-1.75-1.75zm5.577 7.827V6.596a.25.25 0 0 0-.25-.25H4.25a.75.75 0 0 0-.75.75v5.577zm-6.327 1.5h6.327V20.5H4.25a.75.75 0 0 1-.75-.75zm7.827 0V20.5h5.577a.75.75 0 0 0 .75-.75v-5.327a.25.25 0 0 0-.25-.25z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "receive-giveaway-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M7.994 20.5a.91.91 0 0 0 .908-.91.91.91 0 0 0-.908-.911H6.6l8.094-8.115a.91.91 0 0 0 0-1.288.906.906 0 0 0-1.284 0l-8.095 8.115v-1.39a.91.91 0 1 0-1.816.001v3.587c0 .503.407.91.908.91z\"/><path fill-rule=\"evenodd\" d=\"M17.775 8.962A2.73 2.73 0 0 0 20.5 6.23a2.73 2.73 0 0 0-2.726-2.73 2.73 2.73 0 0 0-2.725 2.73 2.73 2.73 0 0 0 2.726 2.732m.907-2.731a.91.91 0 0 1-.907.911.91.91 0 0 1-.908-.911.91.91 0 0 1 .907-.912c.5 0 .908.406.908.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "savings-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "search-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75m0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-10-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-12-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-16-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-20-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-24-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-28-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-32-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "sell-48-regular": {
      "body": "<g fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M12 2a4.2 4.2 0 0 0-1.415.245 3.9 3.9 0 0 0-1.222.71c-.355.308-.642.68-.84 1.096a3.1 3.1 0 0 0-.304 1.326V6.5H4.514a1.756 1.756 0 0 0-1.76 1.875l.831 11.535A2.26 2.26 0 0 0 5.848 22h12.305a2.26 2.26 0 0 0 2.262-2.09l.83-11.535a1.756 1.756 0 0 0-1.76-1.875H15.78V5.377c0-.459-.104-.91-.303-1.326a3.4 3.4 0 0 0-.84-1.096 3.9 3.9 0 0 0-1.222-.71A4.2 4.2 0 0 0 12 2m2.268 4.5V5.377c0-.232-.053-.464-.158-.685a1.9 1.9 0 0 0-.47-.608 2.3 2.3 0 0 0-.74-.429A2.7 2.7 0 0 0 12 3.5a2.7 2.7 0 0 0-.9.155 2.3 2.3 0 0 0-.74.429 1.9 1.9 0 0 0-.47.608 1.6 1.6 0 0 0-.158.685V6.5zM4.263 8.268A.25.25 0 0 1 4.514 8h14.972a.25.25 0 0 1 .251.268l-.83 11.535a.754.754 0 0 1-.755.697H5.849a.754.754 0 0 1-.755-.697z\" clip-rule=\"evenodd\"/><path d=\"M12.756 17c0 .415-.338.75-.756.75a.753.753 0 0 1-.756-.75v-3.708l-1.737 1.699a.76.76 0 0 1-1.07-.008.746.746 0 0 1 .008-1.061l3.025-2.956a.76.76 0 0 1 1.061 0l3.024 2.957c.298.29.301.765.008 1.06a.76.76 0 0 1-1.07.008l-1.737-1.7z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-giveaway-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "send-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16.006 3.5a.91.91 0 0 0-.908.91.91.91 0 0 0 .908.911H17.4l-8.094 8.115a.91.91 0 0 0 0 1.288.906.906 0 0 0 1.284 0l8.095-8.115v1.39a.91.91 0 1 0 1.816-.001V4.41a.91.91 0 0 0-.908-.91z\"/><path fill-rule=\"evenodd\" d=\"M6.226 15.039a2.73 2.73 0 0 0-2.726 2.73A2.73 2.73 0 0 0 6.226 20.5a2.73 2.73 0 0 0 2.725-2.73 2.73 2.73 0 0 0-2.725-2.731m-.908 2.73a.91.91 0 0 1 .908-.911c.499 0 .907.406.907.911a.91.91 0 0 1-.907.912.91.91 0 0 1-.908-.912\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-task-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M16.757 9.303a.75.75 0 0 0-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.037.023zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 0 0-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 0 0-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 0 0 .55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 0 0-.75-.75M4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spend-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "spinner-ios-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "spinner-ios-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10 3a7 7 0 0 0-7 7 .5.5 0 0 1-1 0 8 8 0 1 1 8 8 .5.5 0 0 1 0-1 7 7 0 1 0 0-14\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "stake-wip-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-wip-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M16 12.5a.75.75 0 0 0-1.5 0v3.368l-1.092-.709a.75.75 0 1 0-.817 1.258l2.25 1.462a.75.75 0 0 0 .817 0l2.25-1.462a.75.75 0 0 0-.817-1.258l-1.092.71z\"/><path fill-rule=\"evenodd\" d=\"M4.25 2.375A2.25 2.25 0 0 0 2 4.625v.55c0 .576.217 1.102.573 1.5A2.24 2.24 0 0 0 2 8.175v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55c0 .576.217 1.102.573 1.5a2.24 2.24 0 0 0-.573 1.5v.55a2.25 2.25 0 0 0 2.25 2.25h9.808q.223 0 .435-.042.373.042.757.042a6.75 6.75 0 0 0 1.058-13.418v-.032c0-.576-.217-1.102-.573-1.5.356-.398.573-.924.573-1.5v-.55a2.25 2.25 0 0 0-2.25-2.25zm10.557 5.764a.75.75 0 0 0-.735-.714H4.236a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h6.95a6.7 6.7 0 0 1 3.607-1.336M9.74 10.975H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h4.522a6.7 6.7 0 0 1 .983-2.05m-1.231 3.55H4.235a.75.75 0 0 0-.735.75v.55c0 .414.336.75.75.75h4.466a6.8 6.8 0 0 1-.207-2.05m.796 3.55H4.25a.75.75 0 0 0-.75.75v.55c0 .414.336.75.75.75h6.757a6.8 6.8 0 0 1-1.702-2.05m4.768-12.15a.75.75 0 0 0 .734-.75v-.55a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75v.55c0 .41.328.742.735.75h9.837M10 14.875a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "stake-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.645 19.395 18.575 4.45h-3.48a.724.724 0 0 1 0-1.448H21V8.96a.724.724 0 1 1-1.448 0V5.523L4.669 20.42a.723.723 0 1 1-1.024-1.026\"/><path fill-rule=\"evenodd\" d=\"M10.677 6.843a3.84 3.84 0 0 1-3.838 3.843A3.84 3.84 0 0 1 3 6.843 3.84 3.84 0 0 1 6.839 3a3.84 3.84 0 0 1 3.838 3.843M6.84 9.237c1.318 0 2.39-1.07 2.39-2.394a2.39 2.39 0 0 0-2.39-2.395 2.39 2.39 0 0 0-2.39 2.395 2.39 2.39 0 0 0 2.39 2.394M21 17.178A3.82 3.82 0 0 1 17.182 21a3.82 3.82 0 0 1-3.818-3.823 3.82 3.82 0 0 1 3.818-3.822A3.82 3.82 0 0 1 21 17.177m-3.818 2.373a2.37 2.37 0 0 0 2.37-2.373 2.37 2.37 0 0 0-2.37-2.375 2.37 2.37 0 0 0-2.37 2.374 2.37 2.37 0 0 0 2.37 2.374\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "swap-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.683 2.223a.75.75 0 0 1-.005 1.06l-1.254 1.24H17.1c.685 0 1.697.197 2.56.812.902.642 1.59 1.708 1.59 3.301s-.688 2.66-1.59 3.302c-.863.615-1.875.812-2.56.812H6.9c-.448 0-1.136.14-1.69.534-.515.366-.96.982-.96 2.08 0 1.097.445 1.713.96 2.08.554.394 1.242.533 1.69.533h8.375l-1.252-1.24a.75.75 0 0 1 1.055-1.066l2.55 2.523a.75.75 0 0 1 0 1.066l-2.55 2.523a.75.75 0 0 1-1.055-1.066l1.252-1.24H6.9c-.685 0-1.697-.197-2.56-.812-.902-.642-1.59-1.708-1.59-3.301s.688-2.66 1.59-3.302c.863-.615 1.875-.812 2.56-.812h10.2c.448 0 1.136-.14 1.69-.534.515-.366.96-.982.96-2.08 0-1.097-.445-1.713-.96-2.08-.554-.394-1.242-.533-1.69-.533h-6.676l1.254 1.24a.75.75 0 0 1-1.056 1.066l-2.55-2.523a.75.75 0 0 1 0-1.066l2.55-2.523a.75.75 0 0 1 1.061.006\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "tag-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M19.75 2A2.25 2.25 0 0 1 22 4.25v5.462a3.25 3.25 0 0 1-.952 2.298l-8.5 8.503a3.255 3.255 0 0 1-4.597.001L3.489 16.06a3.25 3.25 0 0 1-.004-4.596l8.5-8.51a3.25 3.25 0 0 1 2.3-.953zm0 1.5h-5.466c-.464 0-.91.185-1.238.513l-8.512 8.523a1.75 1.75 0 0 0 .015 2.462l4.461 4.454a1.755 1.755 0 0 0 2.477 0l8.5-8.503a1.75 1.75 0 0 0 .513-1.237V4.25a.75.75 0 0 0-.75-.75M17 5.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "telegram-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-9.642-2.618q-1.458.607-5.831 2.512-.711.283-.744.553c-.039.304.343.424.862.587l.218.07c.51.166 1.198.36 1.555.368q.486.01 1.084-.4 4.086-2.76 4.218-2.789c.063-.014.149-.032.207.02.059.052.053.15.047.177-.038.161-1.534 1.552-2.308 2.271-.241.225-.413.384-.448.42a9 9 0 0 1-.235.233c-.474.457-.83.8.02 1.36.408.27.735.492 1.061.714.356.242.711.484 1.17.785q.177.116.338.234c.415.296.787.56 1.247.518.267-.024.543-.275.683-1.025.332-1.77.983-5.608 1.133-7.19a1.8 1.8 0 0 0-.017-.393.42.42 0 0 0-.142-.27c-.12-.098-.305-.118-.387-.117-.376.007-.954.207-3.73 1.362\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trade-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.516 21.63a.91.91 0 0 0 1.46.004 1 1 0 0 0 .076-.075l3.202-3.486a.927.927 0 0 0-.049-1.302.91.91 0 0 0-1.29.05l-1.754 1.908V2.922A.92.92 0 0 0 16.247 2a.92.92 0 0 0-.913.922v15.806L13.58 16.82a.91.91 0 0 0-1.29-.049.927.927 0 0 0-.05 1.302l3.203 3.486q.035.038.072.071M7.02 2.37a.91.91 0 0 1 1.46-.004q.04.036.076.075l3.202 3.486a.927.927 0 0 1-.048 1.302.91.91 0 0 1-1.292-.05L8.666 5.272v15.807A.92.92 0 0 1 7.75 22a.92.92 0 0 1-.913-.922V5.272L5.085 7.18a.91.91 0 0 1-1.29.049.927.927 0 0 1-.05-1.302L6.949 2.44a1 1 0 0 1 .072-.071\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "trend-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M3.5 2.75a.75.75 0 0 0-1.5 0v17A2.25 2.25 0 0 0 4.25 22h17a.75.75 0 0 0 0-1.5h-17a.75.75 0 0 1-.75-.75z\"/><path d=\"M19.014 4.482a.74.74 0 0 0-1.037-.038L15.783 6.45c-.3.275-.32.74-.045 1.04s.742.32 1.043.046l.849-.777c-.75 5.73-5.142 10.015-11.142 10.015a.737.737 0 1 0 0 1.473c6.9 0 11.87-5.016 12.63-11.503l.856.91a.74.74 0 0 0 1.043.031.736.736 0 0 0 .033-1.04z\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "twitter-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M22 5.011a7.9 7.9 0 0 1-2.357.676 4.28 4.28 0 0 0 1.805-2.374c-.806.5-1.687.853-2.607 1.042A4 4 0 0 0 15.848 3c-2.265 0-4.102 1.92-4.102 4.291q0 .506.105.978C8.44 8.09 5.417 6.382 3.394 3.786a4.4 4.4 0 0 0-.556 2.157c0 1.489.724 2.802 1.825 3.572a3.95 3.95 0 0 1-1.858-.537v.055c0 2.079 1.414 3.813 3.29 4.207a4 4 0 0 1-1.853.075c.522 1.704 2.038 2.945 3.832 2.98A8 8 0 0 1 2 18.07 11.24 11.24 0 0 0 8.29 20c7.546 0 11.673-6.539 11.673-12.21q0-.278-.013-.554A8.6 8.6 0 0 0 22 5.01\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "virtual-network-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "virtual-network-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M4.825 6.12a.5.5 0 0 1 .055.705L2.159 10l2.72 3.175a.5.5 0 1 1-.759.65l-3-3.5a.5.5 0 0 1 0-.65l3-3.5a.5.5 0 0 1 .705-.055m10.295 7.055a.5.5 0 0 0 .76.65l3-3.5a.5.5 0 0 0 0-.65l-3-3.5a.5.5 0 1 0-.76.65L17.842 10zM6 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2\"/>",
      "left": 0,
      "top": 0,
      "width": 20,
      "height": 20
    },
    "wallet-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "wallet-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.5 13.75a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75M3 5h.014A2.25 2.25 0 0 1 5.25 3h11.5A2.25 2.25 0 0 1 19 5.25v.837a3.25 3.25 0 0 1 2.5 3.163v8.5A3.25 3.25 0 0 1 18.25 21h-12A3.25 3.25 0 0 1 3 17.75zm15.25 2.5H4.5v10.25c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 20 17.75v-8.5a1.75 1.75 0 0 0-1.75-1.75M17.5 6v-.75a.75.75 0 0 0-.75-.75H5.25a.75.75 0 0 0 0 1.5z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "warning-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "warning-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M14 10.55a.75.75 0 0 1 .75.75v5a.75.75 0 1 1-1.5 0v-5a.75.75 0 0 1 .75-.75m0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12.039 5.208c.86-1.529 3.062-1.529 3.922 0l8.685 15.44c.844 1.5-.24 3.354-1.96 3.354H5.314c-1.721 0-2.805-1.853-1.961-3.353zm2.615.736a.75.75 0 0 0-1.308 0l-8.685 15.44a.75.75 0 0 0 .654 1.118h17.37a.75.75 0 0 0 .654-1.118z\"/>",
      "left": 0,
      "top": 0,
      "width": 28,
      "height": 28
    },
    "withdraw-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "withdraw-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 18.25V5h2.989a.75.75 0 1 0 0-1.5H2.75a.75.75 0 1 0 0 1.5h2.99v13.25a2.25 2.25 0 0 0 2.252 2.25h8.013a2.25 2.25 0 0 0 2.253-2.25M16.756 5H7.242v13.25c0 .414.337.75.751.75h8.013a.75.75 0 0 0 .75-.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "arrow-down-primary-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-down-primary-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.22.933a.75.75 0 0 1 1.06 0L8 7.653l6.72-6.72a.75.75 0 1 1 1.06 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L.22 1.994a.75.75 0 0 1 0-1.06\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 10
    },
    "arrow-right-gray-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "arrow-right-gray-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M5.74 3.2a.75.75 0 0 0-.04 1.06L9.227 8 5.7 11.74a.75.75 0 1 0 1.1 1.02l4-4.25a.75.75 0 0 0 0-1.02l-4-4.25a.75.75 0 0 0-1.06-.04\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "close-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47z\"/>",
      "left": 0,
      "top": 0,
      "width": 16,
      "height": 16
    },
    "delisting-10-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-12-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-16-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-20-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-24-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-28-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-32-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "delisting-48-regular": {
      "body": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M4.25 5A2.25 2.25 0 0 0 2 7.25v9.5A2.25 2.25 0 0 0 4.25 19h15.5A2.25 2.25 0 0 0 22 16.75v-9.5A2.25 2.25 0 0 0 19.75 5zM3.5 7.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75V9.5h-17zm0 3.75v5.75c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V11z\" clip-rule=\"evenodd\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "deposit-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M11.223 9a.75.75 0 0 1 1.502 0v4.298l.996-.909a.75.75 0 0 1 1.06.048.75.75 0 0 1-.047 1.06l-2.253 2.057a.75.75 0 0 1-1.014 0l-2.253-2.058a.75.75 0 0 1-.048-1.06.75.75 0 0 1 1.062-.047l.995.91z\"/><path fill-rule=\"evenodd\" d=\"M18.26 5.75V19h2.989a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5h2.99V5.75A2.25 2.25 0 0 1 7.992 3.5h8.013a2.25 2.25 0 0 1 2.253 2.25M16.756 19H7.242V5.75A.75.75 0 0 1 7.993 5h8.013a.75.75 0 0 1 .75.75z\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "divident-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.75 1.998a3.752 3.752 0 0 0-.75 7.43V11.5H7.75a2.25 2.25 0 0 0-2.25 2.25v.825a3.754 3.754 0 0 0 .752 7.43A3.752 3.752 0 0 0 7 14.574v-.825a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 .75.75v.825a3.754 3.754 0 0 0 .752 7.43 3.752 3.752 0 0 0 .748-7.43v-.825a2.25 2.25 0 0 0-2.25-2.25H12.5V9.427a3.754 3.754 0 0 0-.75-7.43M9.498 5.75a2.252 2.252 0 1 1 4.504 0 2.252 2.252 0 0 1-4.504 0M4 18.252a2.252 2.252 0 1 1 4.505 0 2.252 2.252 0 0 1-4.505 0M17.252 16a2.252 2.252 0 1 1 0 4.504 2.252 2.252 0 0 1 0-4.504\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "gift-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "gift-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.5 0a3.25 3.25 0 0 1 2.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 0 1-1 1.225v5.775a3.25 3.25 0 0 1-3.066 3.245L13.75 20h-9.5a3.25 3.25 0 0 1-3.245-3.066L1 16.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C0 5.56.56 5 1.25 5h2.511a3.25 3.25 0 0 1 5.24-3.827A3.24 3.24 0 0 1 11.5 0M8.25 10.999H2.5v5.751a1.75 1.75 0 0 0 1.606 1.744l.144.006h4zm7.25 0H9.75V18.5h4a1.75 1.75 0 0 0 1.744-1.607l.006-.143zM8.25 6.5H1.5v3l6.75-.001zm8.25 3v-3H9.75v2.999zm-5-8a1.75 1.75 0 0 0-1.75 1.75v1.749h1.774l.12-.005A1.75 1.75 0 0 0 11.5 1.5m-5 0a1.75 1.75 0 0 0-.144 3.494l.12.005H8.25V3.25l-.006-.144A1.75 1.75 0 0 0 6.5 1.5\"/>",
      "left": 0,
      "top": 0,
      "width": 18,
      "height": 20
    },
    "logout-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "logout-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M12.5 4.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zM15.743 6a.5.5 0 1 0-.499.867 6.5 6.5 0 1 1-6.494.004.5.5 0 1 0-.5-.866A7.5 7.5 0 1 0 15.743 6\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "merger-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M3 6.75A.75.75 0 0 1 3.75 6h3.257A3.75 3.75 0 0 1 9.66 7.098l2.95 2.95a3.25 3.25 0 0 0 2.298.952h4.53L15.72 7.28a.75.75 0 1 1 1.06-1.06l4.999 5a.75.75 0 0 1 0 1.06l-4.999 5a.75.75 0 1 1-1.06-1.06l3.718-3.72h-4.47a3.25 3.25 0 0 0-2.357 1.012l-2.68 2.82A3.75 3.75 0 0 1 7.212 17.5H3.75a.75.75 0 0 1 0-1.5h3.462a2.25 2.25 0 0 0 1.631-.7l2.68-2.822q.388-.406.853-.709a4.8 4.8 0 0 1-.828-.66l-2.95-2.95a2.25 2.25 0 0 0-1.59-.659H3.75A.75.75 0 0 1 3 6.75\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-10-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-12-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-16-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-20-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-24-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-28-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-32-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "piggy-bank-48-regular": {
      "body": "<g fill=\"currentColor\"><path d=\"M17.253 12.124A1.126 1.126 0 1 1 15 12.123a1.126 1.126 0 0 1 2.252.001\"/><path fill-rule=\"evenodd\" d=\"M12.99 6.801q.01-.15.01-.302a4.5 4.5 0 1 0-8.367 2.302C3.003 10.077 2 11.824 2 13.75c0 2.112 1.206 4.008 3.119 5.305v2.231c0 .394.32.714.713.714h3.956a.714.714 0 0 0 .714-.714v-.48a12 12 0 0 0 1.988-.043v.523a.71.71 0 0 0 .714.714h3.955c.394 0 .714-.32.714-.714v-2.62c1.075-.854 1.873-1.915 2.281-3.096h1.133c.394 0 .713-.32.713-.714v-3.3a.714.714 0 0 0-.713-.714H19.65c-.47-.798-1.127-1.518-1.929-2.125l.196-3.268a.426.426 0 0 0-.63-.398l-3.501 1.896q-.39-.087-.795-.147M8.5 9.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4.14-1.232a4.5 4.5 0 0 1-7.034 1.677C4.227 11.005 3.5 12.364 3.5 13.751c0 1.5.854 2.974 2.46 4.063l.659.446v2.241h2.383v-1.286l1.59.095a11 11 0 0 0 1.742-.038l1.656-.173v1.402h2.383v-2.558l.567-.45c.888-.706 1.495-1.54 1.797-2.413l.35-1.01H20.5v-1.727h-1.708l-.435-.74c-.36-.61-.878-1.187-1.542-1.69l-.64-.485.129-2.138-2.295 1.243-.546-.12q-.403-.09-.823-.146\" clip-rule=\"evenodd\"/></g>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "shield-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M11.723 4.084a.5.5 0 0 1 .554 0 15.05 15.05 0 0 0 6.294 2.421A.5.5 0 0 1 19 7v4.5c0 3.891-2.307 6.73-6.82 8.467a.5.5 0 0 1-.36 0C7.307 18.23 5 15.39 5 11.5V7a.5.5 0 0 1 .43-.495 15.05 15.05 0 0 0 6.293-2.421m-.124 1.262A16 16 0 0 1 6 7.428V11.5c0 3.392 1.968 5.863 6 7.463 4.032-1.6 6-4.071 6-7.463V7.428a16 16 0 0 1-5.6-2.082l-.4-.249z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-backward-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-2.5.5a.5.5 0 0 0 0-1h-4.793l1.647-1.646a.5.5 0 0 0-.708-.708l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 0 0 .708-.708L15.707 18z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "split-forward-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.78 3.738a2.25 2.25 0 0 0-1.56 0L9.913 5.33a.25.25 0 0 0-.163.234v2.793a4.8 4.8 0 0 0-1.5 0V5.564a1.75 1.75 0 0 1 1.143-1.641L13.7 2.33a3.75 3.75 0 0 1 2.6 0l4.307 1.592a1.75 1.75 0 0 1 1.143 1.641v7.018a6.5 6.5 0 0 0-1.5-.973V5.564a.25.25 0 0 0-.163-.234zm-1.173 7.384c.2.074.382.183.538.318a6.5 6.5 0 0 0-1.589.893L9.78 10.937a2.25 2.25 0 0 0-1.56 0L3.913 12.53a.25.25 0 0 0-.163.234v5.67a.25.25 0 0 0 .163.235L8.22 20.26a2.25 2.25 0 0 0 1.56 0l1.592-.589q.257.721.665 1.353l-1.737.642a3.75 3.75 0 0 1-2.6 0l-4.307-1.591a1.75 1.75 0 0 1-1.143-1.642v-5.67a1.75 1.75 0 0 1 1.143-1.641L7.7 9.53a3.75 3.75 0 0 1 2.6 0zm-2.875 2.129a.75.75 0 0 1 .733.138 6.5 6.5 0 0 0-.926 1.516l-1.79.62v2.7a.75.75 0 1 1-1.5 0v-2.7l-2.48-.857a.75.75 0 1 1 .49-1.418l2.74.948zm.037-5.583a.75.75 0 1 1 .49-1.418l2.659.92a.25.25 0 0 0 .163 0l2.65-.92a.75.75 0 1 1 .492 1.418l-2.65.919a1.75 1.75 0 0 1-1.145 0zM23 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-8.5-.5a.5.5 0 0 0 0 1h4.793l-1.647 1.646a.5.5 0 0 0 .708.708l2.5-2.5a.5.5 0 0 0 0-.708l-2.5-2.5a.5.5 0 0 0-.708.708L19.293 17z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "storage-lock-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M10.75 13.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5M7.25 5.5a3.5 3.5 0 1 1 7 0v1h1a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-9A2.25 2.25 0 0 1 4 16.25v-7.5A2.25 2.25 0 0 1 6.25 6.5h1zm5.5 0a2 2 0 1 0-4 0v1h4zM5.5 8.75v7.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75M9.75 21a2.25 2.25 0 0 1-2.122-1.5h8.122a2.75 2.75 0 0 0 2.75-2.75v-6.622A2.25 2.25 0 0 1 20 12.25v4.5A4.25 4.25 0 0 1 15.75 21z\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "toggle-10-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-12-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-16-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-20-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-24-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-28-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-32-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "toggle-48-regular": {
      "body": "<g fill=\"currentColor\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M15.857.092c1.74-.1 3.478-.092 5.218-.092h8.817c1.774 0 3.512-.008 5.251.092 1.581.09 3.121.282 4.654.711 3.227.902 6.045 2.786 8.082 5.457a15.236 15.236 0 0 1 0 18.48c-2.037 2.67-4.855 4.555-8.082 5.457-1.533.43-3.073.62-4.654.711-1.739.1-3.477.091-5.217.091L21.107 31c-1.772 0-3.51.008-5.25-.092-1.58-.09-3.12-.282-4.653-.71-3.227-.903-6.045-2.788-8.082-5.458a15.234 15.234 0 0 1 0-18.48C5.159 3.59 7.977 1.705 11.204.803c1.533-.429 3.073-.62 4.653-.71\" part=\"background\"/><path d=\"M35.5 29C42.956 29 49 22.956 49 15.5S42.956 2 35.5 2 22 8.044 22 15.5 28.044 29 35.5 29\" part=\"knob\"/></g>",
      "left": 0,
      "top": 0,
      "width": 51,
      "height": 31
    },
    "up-down-arrows-10-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-12-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-16-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-20-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-24-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-28-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-32-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    },
    "up-down-arrows-48-regular": {
      "body": "<path fill=\"currentColor\" d=\"M15.723 20.668c.177.201.458.332.775.332.315 0 .595-.13.772-.33q.041-.03.08-.067l3.392-3.137c.363-.336.34-.86-.052-1.172s-1.004-.292-1.367.044l-1.857 1.718V3.83c0-.458-.434-.829-.968-.829s-.967.371-.967.83v14.225l-1.857-1.717c-.363-.336-.975-.356-1.367-.044-.392.311-.415.836-.052 1.172l3.392 3.137q.037.034.076.064M6.726 3.333C6.902 3.13 7.184 3 7.501 3c.315 0 .595.13.772.33q.04.03.08.067l3.392 3.137c.363.336.34.86-.052 1.172s-1.004.292-1.367-.044L8.468 5.944V20.17c0 .458-.433.829-.967.829-.535 0-.968-.371-.968-.83V5.946L4.677 7.662c-.363.336-.975.355-1.367.044s-.415-.836-.052-1.172L6.65 3.397q.036-.034.076-.064\"/>",
      "left": 0,
      "top": 0,
      "width": 24,
      "height": 24
    }
  }
};