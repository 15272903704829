'use client';

import { ReactElement, cloneElement, FC } from 'react';
import { useComposableModal } from '@bts-web/utils-context';
import { ButtonComponentProps } from '../../types';
import { UserAccessModal } from '../composableModals';
import { useClientUserInfo } from '../../userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';

interface UserAccessWithRoleCheckProps {
  children: ReactElement<ButtonComponentProps>;
}

export const UserAccessWithRoleCheck: FC<UserAccessWithRoleCheckProps> = ({
  children,
}) => {
  const userData = useClientUserInfo();

  const { showModal } = useComposableModal();

  const { isReadOnlyUser, businessApproved } = userData;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    if (!businessApproved) {
      showModal(UserAccessModal, {
        type: 'warningTrade',
      });

      return;
    }

    showModal(UserAccessModal, {
      type: 'readOnly',
    });
  };

  if (isReadOnlyUser) {
    return cloneElement(children, {
      onClick: handleClick,
    });
  }

  return children;
};
