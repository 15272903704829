'use client';

import { FC, memo } from 'react';
import { useSearchParams } from 'next/navigation';
import { css } from '@bts-web/utils-style-engine';
import { AmountInput, AmountInputWrapper } from '../../../common';
import { useTradeForm } from './hooks/useTradeForm';
import { DisplaySwitchWrapper } from '../../../common/components/DisplaySwitchWrapper';
import { BelowInputInfoTable } from '../../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { MainTradeFormErrorBox } from '../MainTradeFormErrorBox/MainTradeFormErrorBox';
import { QuickSelectButtons } from './subcomponents/QuickSelectButtons';
import { DividerElement } from './subcomponents/DividerElement';
import { verifyLinkKey } from '../constants';
import { TradeFormProps } from './types';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';
import { VerificationLinkForm } from './subcomponents/VerificationLinkForm';
import { HiddenInputs } from './subcomponents/HiddenInputs';
import { SubmitSection } from './subcomponents/SubmitSection';

export const MainTradeFormElement: FC<TradeFormProps> = ({
  assetDetails,
  currentTradeDetails,
  staticValues,
  isDataLoading,
}) => {
  const { formState, handlers } = useTradeForm({
    assetDetails,
    currentTradeDetails,
    staticValues,
  });

  const searchParams = useSearchParams();

  const verifyLink = searchParams.get(verifyLinkKey);

  if (verifyLink) {
    return (
      <VerificationLinkForm
        formState={formState}
        staticValues={staticValues}
        currentTradeDetails={currentTradeDetails}
        verifyLink={verifyLink}
      />
    );
  }

  const submitDisabled =
    formState.inputValue?.length === 0 ||
    formState.formHasAnyErrors ||
    formState.allButtonsDisabled ||
    Number(formState.inputValue.replace(',', '.')) === 0;

  return (
    <form
      onSubmit={handlers.onFormSubmit}
      data-testid="trade-form"
      className={css({ flex: 1, display: 'flex', flexDir: 'column' })}
      aria-label="trade form container"
    >
      <HiddenInputs
        formState={formState}
        assetDetails={assetDetails}
        staticValues={staticValues}
      />

      <AmountInputWrapper
        inputContextData={formState.inputContextData}
        switcherElement={
          staticValues.transactionType === 'sell' ? (
            <DisplaySwitchWrapper
              parentValue={formState.displayType}
              items={[
                {
                  label: staticValues.translatedValues.selectorLabels.fiat,
                  value: 'FIAT',
                },
                {
                  label: staticValues.translatedValues.selectorLabels.asset,
                  value: 'ASSET',
                },
              ]}
              onChange={handlers.onChangeDisplayType}
            />
          ) : null
        }
      >
        {({ inputRef, modifiedBlur }) => {
          return (
            <AmountInput
              ref={inputRef}
              currentLocale={staticValues.currentLocale}
              placeholder={formState.inputPlaceholder}
              value={formState.inputValue}
              onBlur={modifiedBlur}
              onChange={(e) => handlers.onTradeInputChange(e.target.value)}
            />
          );
        }}
      </AmountInputWrapper>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: staticValues.translations['units'],
              value: formState.convertedSummary,
            },
            {
              title: staticValues.translations['minimum'],
              value: intlAssetValueFormatting(
                currentTradeDetails.minimumTradeAmount.FIAT?.value ?? 0,
                {
                  currency: staticValues.currency,
                  locale: staticValues.currentLocale,
                },
              ),
            },
          ]}
        />

        {formState.hasError && (
          <MainTradeFormErrorBox
            errors={formState.hasError}
            translatedValues={staticValues.translatedValues}
          />
        )}
      </div>

      <DividerElement />

      <QuickSelectButtons
        items={currentTradeDetails.quickSelectTradeItems}
        onQuickSelectButtonClick={handlers.onQuickSelectButtonClick}
        disabled={formState.allButtonsDisabled}
        displayType={formState.displayType}
        inputValue={formState.inputValue}
      />

      <SubmitSection
        loading={(formState.loading || isDataLoading) && !submitDisabled}
        disabled={submitDisabled}
        staticValues={staticValues}
      />
    </form>
  );
};

export default memo(MainTradeFormElement);
