'use client';

import { ReactElement } from 'react';
import { AcceptsDataTestid } from '@bts-web/utils-test/types';
import { css } from '@bts-web/utils-style-engine';
import { CheckboxToggle } from '../../../../../../../common';

interface AcceptOfferTermProps extends AcceptsDataTestid {
  label: string | ReactElement;
  termsAgreed: boolean;
  onToggleCallback: () => void;
}

const AcceptOfferTerm = ({
  label,
  termsAgreed,
  onToggleCallback,
  'data-testid': testIdSuffix,
}: AcceptOfferTermProps) => (
  <div
    className={css({ display: 'flex', gap: 'small', alignItems: 'center' })}
    data-testid={
      testIdSuffix ? `accept-offer-term-${testIdSuffix}` : 'accept-offer-term'
    }
    aria-label="accept offer term"
  >
    <p
      className={css({
        color: 'neutrals.text_primary',
        textAlign: 'left',
        fontSize: 'body.medium',
        fontWeight: 'body.medium',
        letterSpacing: 'body.medium',
        lineHeight: 'body.medium',
      })}
    >
      {label}
    </p>

    <CheckboxToggle checked={termsAgreed} onChange={onToggleCallback} />
  </div>
);

export { AcceptOfferTerm };
