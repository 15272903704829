export interface WindowHistoryParam {
  newHistoryState?: { key: string; value: string };
  searchParams: URLSearchParams;
  pathname: string;
}

export const setWindowHistoryState = ({
  newHistoryState,
  searchParams,
  pathname,
}: WindowHistoryParam) => {
  const params = new URLSearchParams(searchParams);

  let currentUrl = pathname;

  if (newHistoryState?.value && newHistoryState?.key) {
    params.set(newHistoryState.key, newHistoryState.value);
  }

  const urlParams = params.toString();

  if (urlParams) {
    currentUrl = `${pathname}?${params.toString()}`;
  }

  // cannot use router.push because it doesn't support shallow routing (navigating without reload) - source: https://github.com/vercel/next.js/discussions/48110
  // additionally, router.push navigation causes issues regarding pagination (IntersectionObserver doesn't trigger anymore after navigating to another asset type)
  window.history.pushState({}, '', currentUrl);
};
