import { css } from '@bts-web/utils-style-engine';
import { FC, PropsWithChildren } from 'react';

const TwoFaLayoutWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: { base: 'small', md: 'medium' },
        textAlign: 'center',
        width: '100%',
        margin: '0 auto',
        maxWidth: '600px',
        py: { base: 'small', md: 'large' },
      })}
    >
      {children}
    </div>
  );
};

export { TwoFaLayoutWrapper };
