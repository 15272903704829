'use client';

import { FC, useCallback } from 'react';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';
import { throttle } from '@bts-web/utils-functions';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';

const CopyToClipboardButton: FC<{
  value: string;
  className?: string;
  children?: string;
  ariaLabel: string;
}> = ({ value, className, children, ariaLabel }) => {
  const { t } = useClientTranslation();

  const { setAppNotification } = useAppNotification();

  const copyToClipboardAndShowNotification = async () => {
    try {
      await navigator.clipboard.writeText(value);

      setAppNotification({
        title: t('success_toast_message_title'),
        visual: 'success',
        customDuration: 3000,
      });
    } catch (error) {
      setAppNotification({
        title: t('inline_error_tranasaction_fail'),
        visual: 'error',
        customDuration: 3000,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCopyToClipboard = useCallback(
    throttle(() => copyToClipboardAndShowNotification(), 4000),
    [value],
  );

  return (
    <ButtonBase
      onClick={() => handleCopyToClipboard()}
      visual="secondary"
      size="small"
      aria-label={ariaLabel}
      className={`${css({
        p: 0,
        px: 'extra_small_3',
        minHeight: '28px',
        borderColor: 'neutrals.fill_tertiary',
      })} ${className}`}
    >
      {children}
      <Icon icon="copy" size="20" />
    </ButtonBase>
  );
};

export { CopyToClipboardButton };
