/**
 * @generated SignedSource<<78a4367b2b6b05c58e037e3d8e7c656d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetListType = "COMMODITY" | "CRYPTOCOIN" | "INDEX" | "SECURITY";
export type AssetsListGroup = "COIN" | "ETC" | "ETF" | "INDEX" | "METAL" | "STOCK" | "TOKEN";
export type AssetsListSortBy = "MARKETCAP" | "PRICE" | "PRICE_CHANGE_1MONTH" | "PRICE_CHANGE_1WEEK" | "PRICE_CHANGE_1YEAR" | "PRICE_CHANGE_24HOURS";
export type SortOrder = "ASC" | "DESC";
export type AssetsListInput = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  group?: AssetsListGroup | null;
  groups?: ReadonlyArray<AssetsListGroup> | null;
  last?: number | null;
  searchTerm?: string | null;
  sortBy?: AssetsListSortBy | null;
  sortOrder?: SortOrder | null;
  type?: AssetListType | null;
  types?: ReadonlyArray<AssetListType> | null;
};
export type AllAssetsForTransferQuery$variables = {
  input?: AssetsListInput | null;
};
export type AllAssetsForTransferQuery$data = {
  readonly assets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly id: string;
        readonly logoUrl: string | null;
        readonly marketCap: number | null;
        readonly name: string | null;
        readonly networkInformation?: {
          readonly defaultNetworkId: string;
          readonly networks: ReadonlyArray<{
            readonly coinNetworkId: string;
            readonly destinationTag: string | null;
            readonly id: string;
            readonly isDepositAllowed: boolean;
            readonly isOperational: boolean;
            readonly isWithdrawalAllowed: boolean;
            readonly logoUrl: string | null;
            readonly minDepositThreshold: PrecisionFloat | null;
            readonly minWithdrawalThreshold: PrecisionFloat | null;
            readonly name: string | null;
            readonly smallDepositFee: PrecisionFloat | null;
            readonly smallDepositThreshold: PrecisionFloat | null;
          } | null> | null;
        } | null;
        readonly portfolio: {
          readonly assetBalance: PrecisionFloat | null;
          readonly fiatBalance: PrecisionFloat | null;
        } | null;
        readonly price: PrecisionFloat | null;
        readonly symbol: string | null;
      };
    } | null> | null;
    readonly totalCount: number | null;
  } | null;
};
export type AllAssetsForTransferQuery = {
  response: AllAssetsForTransferQuery$data;
  variables: AllAssetsForTransferQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssetsConnection",
    "kind": "LinkedField",
    "name": "assets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "symbol",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "marketCap",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetPortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolio",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetBalance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fiatBalance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetNetworkInformation",
                    "kind": "LinkedField",
                    "name": "networkInformation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultNetworkId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Network",
                        "kind": "LinkedField",
                        "name": "networks",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "coinNetworkId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "destinationTag",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isOperational",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDepositAllowed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isWithdrawalAllowed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minDepositThreshold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minWithdrawalThreshold",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "smallDepositFee",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "smallDepositThreshold",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "CryptoAsset",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AllAssetsForTransferQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AllAssetsForTransferQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "eb04fa40df38198920eb1fb1a5cc5ff3",
    "id": null,
    "metadata": {},
    "name": "AllAssetsForTransferQuery",
    "operationKind": "query",
    "text": "query AllAssetsForTransferQuery(\n  $input: AssetsListInput\n) {\n  assets(input: $input) {\n    totalCount\n    edges {\n      node {\n        id\n        symbol\n        logoUrl\n        name\n        marketCap\n        price\n        __typename\n        portfolio {\n          assetBalance\n          fiatBalance\n        }\n        ... on CryptoAsset {\n          networkInformation {\n            defaultNetworkId\n            networks {\n              id\n              name\n              coinNetworkId\n              destinationTag\n              logoUrl\n              isOperational\n              isDepositAllowed\n              isWithdrawalAllowed\n              minDepositThreshold\n              minWithdrawalThreshold\n              smallDepositFee\n              smallDepositThreshold\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a481cfa9f359e501aac388242b1633a";

export default node;
