import { FC, useState } from 'react';
import { TwoFaSectionButtonsWrapper } from '../../../../shared/TwoFaSectionButtonsWrapper';
import { DigitCodeInput } from '../../../../../../DigitCodeInput';
import { ButtonBase } from '../../../../../../ButtonBase/ButtonBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/utils-relay';
import { css } from '@bts-web/utils-style-engine';
import { TTwoFaCommonProps } from '../../../types';
import { postTwoFaCodeInitialSetup } from '../../../../../../../../auth';
import { useRouter } from 'next/navigation';
import { datadogErrorHelper } from '../../../../../../../utils/datadogErrorHelper';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';

export type TwoFaCodeSubmitBoxProps = Pick<
  TTwoFaCommonProps,
  'onContinue' | 'bindRecoveryCodes'
>;

const TwoFaCodeSubmitBox: FC<TwoFaCodeSubmitBoxProps> = ({
  onContinue,
  bindRecoveryCodes,
}) => {
  const { t } = useClientTranslation();

  const router = useRouter();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [code, setCode] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const isCodeValid =
    code.length === 6 && code.split('').every((digit) => /^\d$/.test(digit));

  const onCodeSubmit = async () => {
    setLoading(true);

    try {
      const response = await postTwoFaCodeInitialSetup(code);

      if ('message' in response) {
        throw new Error(response.message);
      } else if ('backup_codes' in response) {
        setLoading(false);

        const combinedValue = response.backup_codes.join(',\n').trimEnd();

        bindRecoveryCodes(combinedValue);

        onContinue();

        router.refresh();
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      processErrors();

      datadogErrorHelper({
        errorMessage: (error as any).message,
        errorSeverity: DatadogErrorLevels.HIGH,
      });

      setLoading(false);
    }
  };

  return (
    <>
      <DigitCodeInput
        emitCode={(newCode: string) => setCode(newCode)}
        className={css({
          width: '100%',
          maxWidth: '340px',
          margin: '0 auto',
        })}
      />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          visual={'accent'}
          disabled={loading || !isCodeValid}
          isLoading={loading}
          onClick={onCodeSubmit}
        >
          {t('continue')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { TwoFaCodeSubmitBox };
