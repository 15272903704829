import { useClientTranslation } from '@bts-web/utils-lokalise';
import { FC } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { DeleteTwoFaOnClose } from '../../utils/types';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';
import { useComposableModal } from '@bts-web/utils-context';
import { css } from '@bts-web/utils-style-engine';
import { TriggerSetupTwoFaModal } from '../../../TriggerSetupTwoFaModal/TriggerSetupTwoFaModal';

const DeleteTwoFaConfirmationScreen: FC<{
  onClose: DeleteTwoFaOnClose;
}> = ({ onClose }) => {
  const { t } = useClientTranslation();

  const { showModal } = useComposableModal();

  const onTriggerReenable = () => {
    onClose();

    showModal(TriggerSetupTwoFaModal);

    return;
  };

  return (
    <>
      <TwoFaSectionTitle
        title={`${t('2fa_disable_delete_success' as 'done')}`}
      />
      <TwoFaSectionDescription
        textContent={`${t('2fa_disable_delete_success_description' as 'done')}`}
      />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase onClick={onClose} visual="accent" size="large" fullWidth>
          {t('done')}
        </ButtonBase>
        <ButtonBase
          visual={'ghost'}
          onClick={onTriggerReenable}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_disable_delete_re_enable' as 'done')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { DeleteTwoFaConfirmationScreen };
