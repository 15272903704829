'use client';

import { FC, useEffect, useMemo } from 'react';
import {
  MutableNonNullableAsset,
  MutableNonNullableUser,
  TradeInnerTranslatedValues,
} from '../types';
import { TradeControllerVariants, TradeScreenTranslations } from '../../utils';
import { TradeBuyApplicationStateReacter } from '../../Reusable/ApplicationState/TradeBuyApplicationStateReacter.use-client/TradeBuyApplicationStateReacter.use-client';
import { TradeAssetInfoDisplay } from '../../../common/components/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { MainTradeFormElement } from '../MainTradeForm';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import {
  getMaximumTradeAmount,
  getMinimumTradeAmount,
  getTradeQuickSelectButtons,
} from '../utils';
import { useTradeDataRefresher } from './hooks/useTradeDataRefresher';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';

export type MainTradeDataFlowWrapperProps = {
  staticData: {
    currentLocale: string;
    translations: TradeScreenTranslations;
    variant: TradeControllerVariants;
    currency: string;
    translatedInnerValues: TradeInnerTranslatedValues;
  };
  initialData: {
    assetData: MutableNonNullableAsset;
    userData: MutableNonNullableUser;
  };
  settings?: {
    hideTopBorder?: boolean;
  };
};

const mainTradeWrapperStyles = css({
  px: 'medium',
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
});

export const MainTradeDataFlowWrapper: FC<MainTradeDataFlowWrapperProps> = ({
  initialData,
  staticData,
  settings,
}) => {
  const { hideTopBorder } = settings ?? {};

  const { setAppNotification, closeAllNotifications } = useAppNotification();

  const { userData } = initialData;

  const { isDataRefreshing, assetCurrentData } = useTradeDataRefresher({
    assetId: initialData.assetData.id,
    initialAssetData: initialData.assetData,
  });

  const {
    currentLocale,
    translations,
    variant,
    currency,
    translatedInnerValues,
  } = staticData;

  const { __typename: assetType, symbol, id: assetId } = assetCurrentData ?? {};

  const { assetBalance, fiatBalance } = assetCurrentData.portfolio ?? {};

  const { priceBuy, priceSell, minimumBuyAmount, minimumSellAmount } =
    assetCurrentData;

  // ****************************************************
  // ********** EVENT HANDLERS ************
  // ****************************************************

  const isTradeActive = useMemo(() => {
    return variant === 'buy'
      ? assetCurrentData?.isBuyActive
      : assetCurrentData?.isSellActive;
  }, [assetCurrentData?.isBuyActive, assetCurrentData?.isSellActive, variant]);

  useEffect(() => {
    if (!isTradeActive) {
      setAppNotification({
        title: translations.errorAssetNotAvailableTitle,
        subtitle: translations.errorAssetNotAvailableSubtitle,
        visual: 'info',
        customDuration: 'infinite',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTradeActive]);

  useEffect(() => {
    if (!userData?.tradingActive) {
      setAppNotification({
        title: translations.errorTradingNotPossibleTitle,
        subtitle: translations.errorTradingNotPossibleSubtitle,
        visual: 'info',
        customDuration: 'infinite',
      });
    }

    return () => {
      closeAllNotifications();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ****************************************************
  // ********** PROCESSED DATA ************
  // ****************************************************

  const price: PrecisionFloat = variant === 'buy' ? priceBuy : priceSell;

  const maximumTradeAmount = getMaximumTradeAmount({
    assetBalance,
    fiatBalance,
    type: variant,
  });

  const minimumTradeAmount = getMinimumTradeAmount({
    minimumBuyAmount,
    minimumSellAmount,
    price,
    type: variant,
  });

  const quickSelectTradeItems = useMemo(() => {
    return getTradeQuickSelectButtons({
      currentLocale,
      maximumTradeAmount,
      price,
      transactionType: variant,
      translatedMaxString: translatedInnerValues.isMax,
    });
  }, [
    currentLocale,
    maximumTradeAmount,
    price,
    translatedInnerValues.isMax,
    variant,
  ]);

  const finalSymbol =
    assetCurrentData?.__typename === 'MetalAsset' ? translations.grams : symbol;

  const userCannotTrade = !userData.tradingActive;

  return (
    <div className={mainTradeWrapperStyles}>
      <TradeBuyApplicationStateReacter translations={translations} />

      <TradeAssetInfoDisplay
        currentLocale={currentLocale}
        assetData={assetCurrentData}
        symbol={finalSymbol}
        price={price}
        settings={{
          hideTopBorder: hideTopBorder,
        }}
      />

      <MainTradeFormElement
        isDataLoading={isDataRefreshing}
        assetDetails={{
          assetId: assetId,
          assetType: assetType,
          symbol: finalSymbol,
          price: price,
          assetIsNotTradeable: !isTradeActive,
        }}
        currentTradeDetails={{
          maximumTradeAmount: maximumTradeAmount,
          minimumTradeAmount: minimumTradeAmount,
          quickSelectTradeItems: quickSelectTradeItems,
          userCannotTrade: userCannotTrade,
        }}
        staticValues={{
          currentLocale: currentLocale,
          currency: currency,
          translations: translations,
          translatedValues: translatedInnerValues,
          transactionType: variant,
        }}
      />
    </div>
  );
};
