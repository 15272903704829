'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import { useComposableModal } from '@bts-web/utils-context';
import { DeleteTwoFaModal, SetupTwoFaModal } from '../../components';
import { AppUserFullInfo } from '../types';
import { UserInfoContext } from './UserInfoContext';
import { setDataDogUser } from '@bts-web/utils-next-infrastructure';

export const UserInfoProvider: FC<{
  data: AppUserFullInfo;
  children: ReactNode;
}> = ({ children, data }) => {
  const { showModal } = useComposableModal();

  const [userData, setUserData] = useState<AppUserFullInfo>(data);

  useEffect(() => {
    const { twoFactorAuthentication } = data;

    if (twoFactorAuthentication === 'DISABLE_IN_PROGRESS') {
      showModal(DeleteTwoFaModal);
    }

    if (twoFactorAuthentication === 'SETUP_IN_PROGRESS') {
      showModal(SetupTwoFaModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData.id) {
      setDataDogUser(userData.id);
    }
  }, [userData]);

  return (
    <UserInfoContext.Provider
      value={{
        data: userData,
        getUserInfo: () => {
          return userData;
        },
        updateUserInfo: setUserData,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
