'use client';

import React, { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import {
  TopBarMenuItem,
  TopBarMenuItemProps,
} from '../TopBarMenuItem/TopBarMenuItem';

interface ITopBarMenuProps {
  TopBarMenuItems: TopBarMenuItemProps[];
  isWithinDropdown?: boolean;
}

export const TopBarMenu: FC<ITopBarMenuProps> = ({
  TopBarMenuItems,
  isWithinDropdown,
}) => {
  const ItemWrapperElement = isWithinDropdown
    ? DropdownMenuItem
    : React.Fragment;

  return (
    <ul
      aria-label="top menu"
      className={css({
        ...(isWithinDropdown
          ? {
              border: '1px solid',
              borderColor: 'neutrals.stroke_secondary',
              backgroundColor: 'neutrals.stroke_secondary',
              maxHeight: '290px',
              borderRadius: '12px',
              overflow: 'auto',
              '& li': {
                width: '100%',
                '&:first-of-type': {
                  borderTopLeftRadius: '13px',
                  borderTopRightRadius: '13px',
                },
                '&:last-of-type': {
                  borderBottomLeftRadius: '13px',
                  borderBottomRightRadius: '13px',
                },
              },
            }
          : {}),
        display: 'flex',
        flexDirection: 'column',
        gap: '1px',
      })}
    >
      {TopBarMenuItems.map((item) => (
        <li key={`${item.icon}-${item.label}`}>
          {/* // usage of the DropdownMenuItem wrapper is required so the menu closes when an inner
          click is made */}
          <ItemWrapperElement>
            <TopBarMenuItem {...item} />
          </ItemWrapperElement>
        </li>
      ))}
    </ul>
  );
};
