'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { debounce } from '@bts-web/utils-functions';
import { logoutAction } from '../actions';

// 5 minutes
export const INACTIVITY_TIMEOUT_IN_MS = 300000;

const userEvents = [
  'mousedown',
  'mousemove',
  'wheel',
  'keydown',
  'touchstart',
  'scroll',
];

const customEvents = [
  // custom event for identifying a trade offer re-creation
  'onTradeOfferRefetched',
];

const events = [...userEvents, ...customEvents];

let timeout: ReturnType<typeof setTimeout>;

const CheckUserInactivity = () => {
  const router = useRouter();

  const setInactivityTimeout = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      logoutAction().then(() => {
        router.push('/auth/logout?auto=1');

        clearTimeout(timeout);
      });
    }, INACTIVITY_TIMEOUT_IN_MS);
  };

  const debouncedSetInactivityTimeout = debounce(setInactivityTimeout, 500);

  useEffect(() => {
    setInactivityTimeout();

    events.forEach((userEvent) => {
      document.addEventListener(userEvent, debouncedSetInactivityTimeout);
    });

    return () => {
      clearTimeout(timeout);

      events.forEach((userEvent) => {
        document.removeEventListener(userEvent, debouncedSetInactivityTimeout);
      });
    };
  }, []);

  return null;
};

export { CheckUserInactivity };
